import React, {useState, useEffect} from "react";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button'
import FetchUpAllNightBreakdown from "../Graphs/fetchUpAllNightBreakdown";
import FetchUpAllNightTeamBreakdown from "../Graphs/fetchUpAllNightTeamBreakdown";
import FetchUpAllNightBreakdownBoulders from "../Graphs/fetchUpAllNightBreakdownBoulders";
import FetchUpAllNightTeamBreakdownBoulders from "../Graphs/fetchUpAllNightTeamBreakdownBoulders";



const Stats = props => {

    const user = props.user;
    console.log(user)
    const [pushInd,setPushInd] = useState(false);
    const [pushTeam,setPushTeam] = useState(false);
    const [pushTeamB,setPushTeamB] = useState(false);
    const [pushIndB,setPushIndB] = useState(false);
    const [pushOverall,setPushOverall] = useState(false);
 
    return (
      <div>


        <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} onClick={()=>setPushInd(true)} variant="link"  eventKey="0">
                                Individual Ropes
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                           
                                <FetchUpAllNightBreakdown push={pushInd} userID={user.user_id} option="RG" />
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} onClick={()=>setPushIndB(true)} variant="link"  eventKey="1">
                                Individual Boulders
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                        
                                <FetchUpAllNightBreakdownBoulders push={pushIndB} userID={user.user_id} option="RG" />
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} onClick={()=>setPushTeam(true)} variant="link"  eventKey="2">
                                Team Ropes
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <FetchUpAllNightTeamBreakdown push={pushTeam} teamID={user.team_id} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} onClick={()=>setPushTeamB(true)} variant="link"  eventKey="3">
                                Team Boulders
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <FetchUpAllNightTeamBreakdownBoulders push={pushTeamB} teamID={user.team_id} />
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
      </div>
    )
};

export default Stats;