import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';

const ViewFixIt = props => {

    const routeID = props.routeID;
    const userID = props.user.sub;

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [description,setDescription] = useState(false);
    const [fixItList, setFixItList] = useState([]);
    const [ticket,setTicket] = useState("");
    const [isLoaded,setIsLoaded] = useState(false);
    const [error,setError] = useState(null);


    const handleClose = () => setShow(false);
    const handleClose2 = () => setShow2(false);
    const handleShow = () => setShow(true);

    useEffect(()=>{
        viewFix(routeID);
    },[show])



    const viewFix = async (routeID) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_fix_it.php",{
            method: 'POST',
            body: JSON.stringify({
                route_id: routeID
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            setFixItList(result)
            setIsLoaded(true)
        },
        (error) => {
            setIsLoaded(true)
            setError(error)
        }
        )
    }

    const ticketDetails = async (ticketID,description) =>{
        setShow(false);
        setShow2(true);
        await setTicket(ticketID);
        await setDescription(description);
    }

    const handleBack = () => {
        setShow2(false);
        setShow(true);
    }

    const deleteFixIt = async (ticketID) =>{
        await fetch("https://elevationrockgym.com/api/delete_fix_it.php",{
            method: 'POST',
            body: JSON.stringify({
                ticket_id: ticketID
            })
        });
        console.log(ticketID);
       handleBack();
    }

    if(isLoaded){
        return(
            <div>
                <Button onClick={handleShow} >View Tickets</Button> 
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>Active Tickets</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {fixItList.map((item =>(
                                <ListGroup.Item action onClick={()=>ticketDetails(item.ticket_id, item.description)}>
                                    {item.date}
                                </ListGroup.Item>
                            )))}
                        </ListGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Ticket Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                   <p>
                       {description}
                   </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleBack}>Back</Button>
                    <Button variant="primary" onClick={()=>deleteFixIt(ticket)}>Delete</Button>
                </Modal.Footer>
            </Modal>         
            </div>
        )
    } else if(!isLoaded) {
        return(
            <div>
            </div>
        )
    } else if(error){
        return (
            <div>
                <p>Error Loading Fix It</p>
            </div>
        )
    }
}


export default ViewFixIt;