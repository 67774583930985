import React, {useState, useEffect} from "react";
import { Table, Accordion,Card, Button, Spinner } from "react-bootstrap";


const Leaderboard = props => {

    const [leaderboard,setLeaderboard] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [reload,setReload] = useState(0);

    useEffect(()=>{
        fetchLeaderboard();
    },[])

    useEffect(()=>{
        fetchLeaderboard();
    },[reload])

    /// Fetch Leaderboard //
    const fetchLeaderboard = async () => {
        setIsLoaded(false)
    
        await fetch("https://elevationrockgym.com/api/fetch_upallnight_leaderboard.php",{
            method: 'POST'
        }
        ).then(result => result.json())
          .then(
            (result) => {
              setLeaderboard(result);
              setIsLoaded(true)
            }
        )
              
    }

    if(isLoaded){

        return(
            <div>
                <Button onClick={()=>setReload(reload+1)}>Reload</Button>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="0">
                                Individual Male Leaderboard Points
                    
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Feet</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard['male'].map((item)=>(
                                        <tr>
                                            <td>
                                                {item.user_name}
                                            </td>
                                            <td>
                                                {item[`SUM(feet)`]}
                                            </td>
                                            <td>
                                                {item[`SUM(points)`]}
                                            </td>
                                        </tr>
                                    ))}
                
                                </tbody>
                            </Table>
                             
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="4">
                                Individual Male Leaderboard Feet Climbed
                    
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Feet</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard['malefeet'].map((item)=>(
                                        <tr>
                                            <td>
                                                {item.user_name}
                                            </td>
                                            <td>
                                                {item[`SUM(feet)`]}
                                            </td>
                                            <td>
                                                {item[`SUM(points)`]}
                                            </td>
                                        </tr>
                                    ))}
                
                                </tbody>
                            </Table>
                             
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Individual Female Leaderboard Points
                    
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Feet</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard['female'].map((item)=>(
                                        <tr>
                                            <td>
                                                {item.user_name}
                                            </td>
                                            <td>
                                                {item[`SUM(feet)`]}
                                            </td>
                                            <td>
                                                {item[`SUM(points)`]}
                                            </td>
                                        </tr>
                                    ))}
                
                                </tbody>
                            </Table>
                             
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="5">
                                Individual Female Leaderboard Feet Climbed
                    
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="5">
                            <Card.Body>
                            
                            <Table striped bordered hover>
                                <thead>
                                    <tr>
                                        <th>Name</th>
                                        <th>Feet</th>
                                        <th>Points</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {leaderboard['femalefeet'].map((item)=>(
                                        <tr>
                                            <td>
                                                {item.user_name}
                                            </td>
                                            <td>
                                                {item[`SUM(feet)`]}
                                            </td>
                                            <td>
                                                {item[`SUM(points)`]}
                                            </td>
                                        </tr>
                                    ))}
                
                                </tbody>
                            </Table>
                             
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="2">
                                Team Leaderboard Points
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Feet</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaderboard['team'].map((item)=>(
                                            <tr>
                                                <td>
                                                    {item.team_name}
                                                </td>
                                                <td>
                                                    {item[`SUM(feet)`]}
                                                </td>
                                                <td>
                                                    {item[`SUM(points)`]}
                                                </td>
                                            </tr>
                                        ))}
                    
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="3">
                                Team Leaderboard Feet Climbed
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>
                                <Table striped bordered hover>
                                    <thead>
                                        <tr>
                                            <th>Name</th>
                                            <th>Feet</th>
                                            <th>Points</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {leaderboard['teamfeet'].map((item)=>(
                                            <tr>
                                                <td>
                                                    {item.team_name}
                                                </td>
                                                <td>
                                                    {item[`SUM(feet)`]}
                                                </td>
                                                <td>
                                                    {item[`SUM(points)`]}
                                                </td>
                                            </tr>
                                        ))}
                    
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    
                </Accordion>
            </div>
        )

    } else {

        return(
            <div>
                <Spinner animation="border" role="status" /> 
            </div>
        )
    }
  


}

export default Leaderboard;