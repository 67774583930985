import React, {useState, useEffect} from 'react';
import { Spinner, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import './listEquipment.css';
import FormControl from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const AddEquipment = props => {

    const userId = props.userId;
    const type = props.type;

    // Initial Modal
    const [showAddEquipment, setShowAddEquipment] = useState(false);
    const handleCloseAddEquipment = () => setShowAddEquipment(false);
    const handleShowAddEquipment = (type) => {
        setShowAddEquipment(true);
        setEquipmentType(type);
    }

    // Fields
    const [equipmentType,setEquipmentType] = useState("");
    const [equipmentDate,setEquipmentDate] = useState();
    const [equipmentIdentifier,setEquipmentIdentifier] = useState();
    const [equipmentDescription,setEquipmentDescription] = useState();

    //Modal Result
    const [addEquipmentResult,setAddEquipmentResult] = useState("");
    const [showAddEquipmentResult, setShowAddEquipmentResult] = useState(false);
    const handleCloseAddEquipmentResult = () => setShowAddEquipmentResult(false);


    const addEquipment = async () => {
        const data = await fetch("https://elevationrockgym.com/api/add_equipment.php",{
            method: 'POST',
            body: JSON.stringify({
                type: equipmentType,
                identifier: equipmentIdentifier,
                description: equipmentDescription,
                date: equipmentDate,
                user_id: userId    
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setAddEquipmentResult(result);
            setShowAddEquipment(false);
            setShowAddEquipmentResult(true);
            props.equipmentQuery();
        });
    }

    const handleEquipmentDateChange = event => {
        setEquipmentDate(event.target.value);
    }

    const handleEquipmentIdentifierChange = event => {
        setEquipmentIdentifier(event.target.value);
    }
    const handleEquipmentDescriptionChange = event => {
        setEquipmentDescription(event.target.value);
    }
    

    return(
        <div>
            <Button onClick={()=>handleShowAddEquipment(type)}>Add {type}</Button>
            <Modal show={showAddEquipment} onHide={handleCloseAddEquipment}>
                <Modal.Header closeButton>
                    <Modal.Title>Add {equipmentType}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Date</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" onChange={handleEquipmentDateChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Identifier</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="number"  min="1" max="40" onChange={handleEquipmentIdentifierChange} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Description</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl onChange={handleEquipmentDescriptionChange} as="textarea" aria-label="Description" />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={addEquipment}>Add</Button>
                    <Button variant="secondary" onClick={handleCloseAddEquipment}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showAddEquipmentResult} onHide={handleCloseAddEquipmentResult}>
                <Modal.Header closeButton>
                    <Modal.Title>Add {equipmentType} Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {addEquipmentResult}
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button variant="secondary" onClick={handleCloseAddEquipmentResult}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default AddEquipment;