import React, {useState, useEffect} from "react";
import {Bar} from 'react-chartjs-2';
import Spinner from 'react-bootstrap/Spinner';
import ReactSpeedometer from 'react-d3-speedometer';



const MyStats = props => {
    const userID = props.userID;
    const [isLoaded,setIsLoaded] = useState(false);
    const [userTickBreakdown,setUserTickBreakdown] = useState([]);
    console.log(userTickBreakdown)
    const fetchUserTicks = async () => {

  
        
        const data = await fetch('https://elevationrockgym.com/api/fetch_everest_tick_breakdown.php',{
          method: 'POST',
          body: JSON.stringify({
            user_id: userID
        })
        }).then(result => result.json())
        .then(
        (result) => {
            setUserTickBreakdown(result)
            setIsLoaded(true);
        }
        )
     }
      
      const options = {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      }
    
      useEffect(()=>{
        fetchUserTicks();
        
      },[])

      if(isLoaded && userTickBreakdown){
          return(
            <div>
                
                  <ReactSpeedometer
                    value={userTickBreakdown['everest']['vertical']}
                    currentValueText={ 'Total Vertical Feet Climbed: ${value} ft'}
                    minValue={0}
                    maxValue={29032}
                    customSegmentStops={[0,5725,9983,13528,20310,29032]}
                    segmentColors={['#E9F7EF','#A9DFBF','#52BE80','#1E8449','#145A32']}
                    customSegmentLabels={[
                      {
                        text: "Rushmore",
                        position: "OUTSIDE",
                        color: "#17202A",
                      },
                      {
                        text: "Naomi",
                        position: "OUTSIDE",
                        color: "#17202A",
                      },
                      {
                        text: "Kings",
                        position: "OUTSIDE",
                        color: "#17202A",
                      },
                      {
                        text: "Denali",
                        position: "OUTSIDE",
                        color: "#17202A",
                      },
                      {
                        text: "Everest",
                        position: "OUTSIDE",
                        color: "#17202A",
                      },
                    ]}
                  />
                
                 
                <Bar data={userTickBreakdown['grade_breakdown']} options={options}/>
                
            </div>
          )
      }else {
          return(
              <div>
                <Spinner animation="border" role="status" />    
              </div>
          )
        
      }
}

export default MyStats;