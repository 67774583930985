import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import FormControl from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'

const FixIt = props => {

    const routeID = props.routeID;
    const userID = props.user.sub;

    const [show, setShow] = useState(false);
    const [input,setInput] = useState(" ");
    const [show2,setShow2] = useState(false);
    const [status,setStatus] = useState("");

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClose2 = () => setShow2(false);

    
    const fixIt = async (routeID,userID,text) => {
        const data = await fetch("https://elevationrockgym.com/api/submit_fix_it.php",{
            method: 'POST',
            body: JSON.stringify({
                route_id: routeID,
                user_id: userID,
                text: text
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setShow(false);
            setStatus(result);
            setShow2(true);
        });
       
    }

    const handleInput = event => {
        setInput(event.target.value)
    }


    return(
        <div>
            <Button onClick={handleShow} >Submit Ticket </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Enter a Fix It ticket below</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text>Description:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl onChange={handleInput} as="textarea" aria-label="Description" />
                    </InputGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={()=>fixIt(routeID, userID,input)}>Save changes</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={show2} onHide={handleClose2}>
                <Modal.Header closeButton>
                    <Modal.Title>Status</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>
                        {status}
                    </p>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose2}>Close</Button>
                </Modal.Footer>
            </Modal>              
        </div>
    )
}


export default FixIt;