import React, {Link,useState, useEffect} from 'react';
import { Spinner, Alert,Table, Accordion, Card, Button, ListGroup, Modal,ToggleButtonGroup,ToggleButton, ButtonToolbar, InputGroup, Form, DropdownButton, Dropdown, ButtonGroup} from 'react-bootstrap';
import './listEquipment.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Badge  from 'react-bootstrap/Badge';
import SaveLog from './SaveLog';
import './equipMeta.css';

const ViewTodoEquipmentLogs = props => {
    console.log(props)
    const userId = props.user.user_id;
    const userName = props.user.user_name;

    const id = props.equipInfo['id'];
    const duration = props.equipInfo['duration'];
    const type = props.equipInfo['type'];
    const logTypeId= props.equipInfo['logtype_id'];
    const logType = props.equipInfo['logtype'];

    const [equipmentLogList,setEquipmentLogList] = useState([]);
    const [equipmentMeta, setEquipmentMeta] = useState([]);
    const [isLoadedEquip,setIsLoadedEquip] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false)
    const [showSaveLog,setShowSaveLog]  = useState(false)
    const [logState,setLogState] = useState([]);
    const [logID,setLogID] = useState(null);
    const [saveLogLoading,setSaveLogLoading] = useState(false);
    const [error,setError] = useState(null);
    const [showLogs,setShowLogs] = useState(false);


    const [showWarning,setShowWarning] = useState(false);
    
    const handleCloseWarning = () =>{
        setShowWarning(false);
        if(equipmentLogList.length > 1){
            setShowLogs(true);
        } else {
            setShowSaveLog(true);
        }
    }
    
    const [logAlert,setLogAlert] = useState(null)

    // ALERT NOTES //
    
    const [alertList,setAlertList] = useState([]);
    const [warnings,setWarnings] = useState(false);   
    const [clickFunction,setClickFunction] = useState(null);  

    /// Notes //
    const [showAddNote,setShowAddNote] = useState(false);
    const [selectedImage,setSelectedImage] = useState(false);
    const [noteLoading,setNoteLoading] = useState(false);
    const [showNotes,setShowNotes] = useState(false);
    const [equipmentNoteList,setEquipmentNoteList] = useState([]);
    const [showAreUsureDelete,setShowAreUsureDelete] = useState(false);
    const [viewNote,setViewNote] = useState(false);
    const [viewNoteImage,setViewNoteImage] = useState(null);
    const [viewNoteText,setViewNoteText] = useState(null);
    const [noteID,setNoteID] = useState(null);
    const [optionID,setOptionID] = useState(null);
    const [viewNoteDetails,setViewNoteDetails] = useState(false)
    const [noteState,setNoteState] = useState([]);

    //// Dropdown button state based on current log state, make array be maximum size for logs? ////
    const [warningState,setWarningState] = useState([0,0,0,0,0,0,0,0,0]);
    
    


    useEffect(()=>{
        if(showNotes == true){
            viewEquipmentNotes();
        }
    },[showNotes])

    
    const viewEquipmentNotes = async () => {
        console.log(optionID)
        const data = await fetch("https://elevationrockgym.com/api/view_notes.php",{
            method: 'POST',
            body: JSON.stringify({
                option_id: optionID,
                log_id: logID
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setEquipmentNoteList(result)
        },
        (error) => {
            setError(error)
        }
        )
    }

    const noteDetails = async (id,text,image) => {
        await setNoteID(id)
        await setViewNoteImage(image);
        await setViewNoteText(text);
        setViewNoteDetails(true);
        setShowNotes(false);
    }

    const addNote = async () => {
        setNoteLoading(true);
        const formData = new FormData();
        formData.append('my_image',selectedImage)
        formData.append('note_text',noteState);
        formData.append('log_id',logID);
        formData.append('option_id',optionID)
        console.log(formData)
        const data = await fetch("https://elevationrockgym.com/api/add_note.php",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setNoteLoading(false);
            handleBackAddNote();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )
    }

    //// handle warning states //

    //const handleWarningState = (option,i) => {
    //    tempArray = warningState;
     //   tempArray[i] = option;
     //   setWarningState(tempArray);

    // 

    const handleCloseLogs = () => {
        setShowLogs(false)
        props.close();
    }

    const handleShowNotes = (option) => {
        setOptionID(option);
        setShowNotes(true);
        setShowSaveLog(false);
    }
    
    const handleCloseNotes = () => {
        setShowNotes(false);
    }

    const handleBackNotes = () => {
        setShowNotes(false);
        setShowSaveLog(true);
    }

    const handleBackAddNote = () => {
        setShowAddNote(false)
        setShowNotes(true)
    }

    const handleCloseAddNote = () => {
        setShowAddNote(false)
    }

    const handleCloseNoteDetails = () => {
        setViewNoteDetails(false)
    }

    const handleBackNoteDetails = () => {
        setViewNoteDetails(false);
        setShowNotes(true);
    }

    const handleNoteText = event => {
        setNoteState(event.target.value)
    }

    const handleCloseAreUsureDelete = () => {
        setShowAreUsureDelete(false);
    }

    const handleShowAddNote = () => {
        setShowAddNote(true);
        setShowNotes(false);
    }

    const deleteNote = async () => {
        setShowAreUsureDelete(false)
        setNoteLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/delete_note.php",{
            method: 'POST',
            body: JSON.stringify({
                note_id:noteID
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setNoteLoading(false);
            handleBackNotes();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )
    }

    const handleCloseSaveLog = () => {
        setShowSaveLog(false)
        props.close()
    }

    const changeOption = (e,optionId) => {
        
        console.log(e);
        console.log(optionId);

        // Load Log State //
        const logTemp = logState;
        logTemp[optionId] = e;
        setLogState(logTemp);
    }


    

    useEffect(()=>{
        loadEquipmentMeta();
    },[])

    useEffect(()=>{
        if(isLoadedEquip){
            console.log('lodijaos')
            viewEquipmentLogs();
        }
    },[isLoadedEquip])

    const addLog = async () => {
        const data = await fetch("https://elevationrockgym.com/api/add_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                log_length: equipmentMeta[logTypeId]['Layout'].length,
                equipment_id: id,
                equipment_type: type,
                logtype: logTypeId,
                alert: logAlert
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            setLogState(result[1]);
            setLogID(result[0]);
            logDetails(result[0],result[1],result[2])
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const viewEquipmentLogs = async () => {
        const data = await fetch("https://elevationrockgym.com/api/todo_view_equipment_logs.php",{
            method: 'POST',
            body: JSON.stringify({
                equipment_id: id,
                duration: duration,
                logtype: logType,
                logtype_id: logTypeId
            })
        })
        .then(result => result.json())
        .then((result) => {
            setEquipmentLogList(result['current'])
            setAlertList(result['alerts'])
            console.log(result)
            console.log(logTypeId)

            if(result['alerts'].length >0){
                setWarnings(true);
            }
            if(result['current'].length == 1){
                logDetails(result['current'][0].log_id,result['current'][0].log_values,result['current'][0].alert)
                //logDetails(result['current'][0].log_id,result['current'][0].log_values,result['current'][0].alert)
                setIsLoaded(true);
            } else if(result['current'].length > 1) {
                setClickFunction(()=>setShowLogs(true));
                setIsLoaded(true);
            } else if(result['current'].length == 0){
                addLog()
                setIsLoaded(true)
            }
            setShowWarning(true)
        }
        )
    }

    const loadEquipmentMeta = async () => {
        const data = await fetch("https://elevationrockgym.com/api/equipment.json",{
            method: 'POST'
        })
        .then(result => result.json())
        .then(
            (result) =>{
                console.log(result[type])
                setEquipmentMeta(result[type]);
                setIsLoadedEquip(true);
            }
        )
    }

    const logDetails = async (logId,logValues,all) =>{
        
        await setLogAlert(all)
        await setLogID(logId);
        await setLogState(logValues);
    }
    
    const saveLog = async () => {
        setSaveLogLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/save_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                log_state: logState,
                log_id: logID,
                alert: logAlert
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(props)
            setSaveLogLoading(false);
            handleCloseSaveLog();
            props.refresh();
            props.reload();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const signLog = async () => {
        setSaveLogLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/sign_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userId,
                user_name: userName,
                log_state: logState,
                log_id: logID,
                alert: logAlert
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setSaveLogLoading(false);
            handleCloseSaveLog();
            props.reload();
            props.refresh();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const colorArray = ['success','warning','danger'];

    if(isLoaded && isLoadedEquip){
        return(
            <div>
                <Modal show={showWarning} onHide={handleCloseWarning}>
                    <Modal.Header closeButton>
                        <Modal.Title>Warnings</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {warnings ? 
                        <div>
                        
                            {alertList.map((item =>(
                                
                            <Alert variant={colorArray[item.warning]}>
                                <Alert.Heading>{item.date_added}</Alert.Heading>
                                <p>
                                    {equipmentMeta[logTypeId]['Layout'][item.option_id]['text']}
                                </p>
                                <hr />
                                <p className="mb-0">
                                    {item.note_text}
                                </p>
                                </Alert>
                            )))}

                        </div>:
                        <div>
                            No Warnings 
                        </div>
                        }
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseWarning} >Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showLogs} onHide={handleCloseLogs}>
                    <Modal.Header closeButton>
                        <Modal.Title>Equipment Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button onClick={()=>addLog()} >
                            Add Log
                        </Button>
                        <ListGroup>
                            {equipmentLogList.map((item =>(
                            
                                <ListGroup.Item variant={colorArray[item.alert]} action onClick={()=>logDetails(item.log_id,item.log_values,item.alert)}>
                                    {item.last_save}
                                </ListGroup.Item>
    
                            )))}
                        </ListGroup>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseLogs}>Close</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showSaveLog} onHide={handleCloseSaveLog}>
                    <Modal.Header closeButton>
                        <Modal.Title>Save Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                       
                        {equipmentMeta[logTypeId]['Layout'].map((item => (
                            
                            <ButtonGroup>
                                <Button style={{ width: "250px"}} variant="outline-secondary" disabled >{item['text']} [?]</Button>


                                {console.log(logState[item['id']])}
                                    <ToggleButtonGroup onChange={(e) => changeOption(e,item['id'])} type="radio" name="options" defaultValue={logState[item['id']] === undefined ? "0" : logState[item['id']] }>
                                        <ToggleButton className="Btn-G"  value={"0"}>G</ToggleButton>
                                        <ToggleButton className="Btn-TM" value={"1"}>TM</ToggleButton>
                                        <ToggleButton className="Btn-R"  value={"2"}>R</ToggleButton>
                                    </ToggleButtonGroup>
                                
                                <Button variant="outline-secondary" onClick={()=>handleShowNotes(item['id'])}>Notes</Button>

                            
                                
                            </ButtonGroup>

                            
                        )))}




                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={()=>handleCloseSaveLog()}>Back</Button>
                        <Button variant="primary" disabled={saveLogLoading} onClick={()=>saveLog()}>Save</Button>
                        <Button variant="success" disabled={saveLogLoading} onClick={()=>signLog()}>Sign and Submit</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showAddNote} onHide={handleCloseAddNote}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Note</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup>
                            <InputGroup.Text>Notes</InputGroup.Text>
                            <Form.Control as="textarea" aria-label="Add Notes" onChange={handleNoteText} />
                        </InputGroup>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Choose Image</Form.Label>
                            <Form.Control type="file"         
                            name="myImage"
                            onChange={(event) => {
                                setSelectedImage(event.target.files[0]);
                                
                                }} 
                            />
                        </Form.Group>

                        {selectedImage && (
                            <div>
                            <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                            <br />
                            <button onClick={()=>setSelectedImage(null)}>Remove</button>
                            </div>
                        )}
                        
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" disabled={noteLoading} onClick={handleBackAddNote}>Back</Button>
                        <Button variant="primary" disabled={noteLoading} onClick={()=>addNote()}>Add Note</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showNotes} onHide={handleCloseNotes}>
                    <Modal.Header closeButton>
                        <Modal.Title>Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {equipmentNoteList.map((item =>(
                                <ListGroup.Item action onClick={()=>noteDetails(item.note_id,item.note_text,item.image_url)}>
                                    {item.note_text}
                                </ListGroup.Item>
                            )))}
                        </ListGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleBackNotes}>Back</Button>
                        <Button variant="primary" onClick={handleShowAddNote} >
                            Add Note
                        </Button>
                    </Modal.Footer>
                </Modal>  
                <Modal show={viewNoteDetails} onHide={handleCloseNoteDetails}>
                    <Modal.Header closeButton>
                        <Modal.Title>Note Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                                <div>
                                    {viewNoteText}
                                </div>
                                <div>
                                    <img alt="not found" width={"250px"} src={"https://elevationrockgym.com/api/uploads/" + viewNoteImage} />
                                </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" disabled={noteLoading} onClick={handleBackNoteDetails}>Back</Button>
                        <Button variant="secondary" disabled={noteLoading} onClick={()=>setShowAreUsureDelete(true)}>Delete</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showAreUsureDelete} onHide={handleCloseAreUsureDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this note?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAreUsureDelete}>No</Button>
                        <Button variant="primary" onClick={() => deleteNote()}>Yes</Button>
                    </Modal.Footer>
                </Modal>    
            </div>
        )
    } else {
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default ViewTodoEquipmentLogs;