import React, {useState,useEffect} from 'react';
import { Card, CardGroup,Spinner, Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import BoulderClub from './BoulderClub';

const Competitions = props => {
    const bla='ds';
    return(
        <div>
            <CardGroup>
                <Card style={{ width: '18rem' }}>
                    <Link to='/boulderclub' >
                        <Card.Img variant="top" src="https://www.elevationrockgym.com/app/images/bc_logo2.png" />
                    </Link>

                    <Card.Body>
                    <Card.Title>Spring Boulder Club</Card.Title>
                    <Card.Text>
                        Click to enter Spring Boulder Club
                    </Card.Text>
                <Link to='/boulderclub' ><Button variant="primary" >Compete!</Button></Link>
                    </Card.Body>
                </Card> 
            </CardGroup>
            
        </div>
    )
}

export default Competitions;