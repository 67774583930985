import React, {useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Button,Spinner, InputGroup, Form} from 'react-bootstrap';
import Scorecard from '../UpAllNight/Scorecard';

import LogoutButton from '../Login/logoutButton';
import LoginButton from '../Login/loginButton';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import BoulderClub from './BoulderClub';
import Leaderboard from '../UpAllNight/leaderboard';
import Stats from '../UpAllNight/Stats';

const UpAllNight = () => {



    useEffect(() => {
        if(isAuthenticated){
            checkStatus(user)
        }
    
    },[]);
     


    const { user, isAuthenticated } = useAuth0();
    const [userProfile,setUserProfile] = useState(null);
    const [statusComplete,setStatusComplete] = useState(false);
    const [category,setCategory] = useState(null);
    const [buttonDisabled,setButtonDisabled] = useState(true);


    const checkStatus = async (user) => {
        const userID = user.sub;
        const userEmail = user.email;
        const userNick = user.nickname;
        const data = await fetch("https://elevationrockgym.com/api/fetch_upallnight_profile.php",{
          method: 'POST',
          body: JSON.stringify({
            user_id: userID,
            user_email: userEmail,
            user_nick: userNick   
          })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result);
          }
        );
    }
    
    const addUpAllNight = async (user) => {
        const userID = user.sub;
        const userEmail = user.email;
        const userNick = user.nickname;
        const data = await fetch("https://elevationrockgym.com/api/add_upallnight_profile.php",{
          method: 'POST',
          body: JSON.stringify({
            user_id: userID,
            user_email: userEmail,
            user_nick: userNick,
            category: category   
          })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result);
          }
        );

    }
    
    const handleChange = event => {
        setButtonDisabled(false);
        setCategory(event.target.value)
    }
  
    if(isAuthenticated && userProfile ){
        return(
            <div>
                <Tabs defaultActiveKey="scorecard" id="browseList" >
                    <Tab eventKey="scorecard" title="Scorecard">
                        <Tabs defaultActiveKey="routes" id="browseList" >
                            <Tab eventKey="routes" title="Routes">
                                <Scorecard user={userProfile} type="R"/>
                            </Tab>
                            <Tab eventKey="boulders" title="Upstairs Boulders">
                                <Scorecard user={userProfile} type="B"/>
                            </Tab>
                            <Tab eventKey="dboulders" title="Downstairs Boulders">
                                <Scorecard user={userProfile} type="DB"/>
                            </Tab>
                        </Tabs>
                        
                    </Tab>
                    <Tab eventKey="leaderboard" title="Leaderboard">
                        <Leaderboard />
                    </Tab>
                    <Tab eventKey="stats" title="Stats">
                        <Stats user={userProfile} />
                    </Tab>
                </Tabs>
            </div>
        )
    } else if(isAuthenticated) {
        checkStatus(user)
        
        return(
            <div>
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Category (For Individual)</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={handleChange}>
                        <option> Please Select Category </option>
                        <option value="0">Male</option>
                        <option value="1">Female</option>
                    </Form.Control>
                </InputGroup>
                <Button disabled={buttonDisabled} onClick={()=>addUpAllNight(user)}>Join</Button> Up All Night
            </div>
        )
    
    } else {
        return(
            <div>
                Please <LoginButton />   
            </div>
        )
    }
   
}

export default UpAllNight;