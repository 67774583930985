import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import { isObject } from 'lodash';


const ConsGrade = props => {

  
    const userID = props.userID;
    const routeID = props.route_id;
    const type = props.type;
    const [value, setValue] = useState(props.grade);
    const [gradedByUser,setGradedByUser] = useState(false);
    const [userGrades, setUserGrades] = useState([]);

    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);


    useEffect(()=>{
      displayGrade()
    },[gradedByUser])

    useEffect(()=>{
      console.log(userGrades);
      updateGradedBy();
      console.log('heyu')
    },[userGrades])


    const displayGrade = async () => {

      
      const data = await fetch('https://elevationrockgym.com/api/fetch_user_grades.php',{
        method: 'POST',
        body: JSON.stringify({
          user: userID,
          id: routeID
      })
      }).then(result => result.json())
      .then((result) => {
          console.log(result)
          setUserGrades(result)
        }
      );

      

    

     
    }

    const updateGradedBy = async () => {
      console.log(userGrades.length)
      if(userGrades.length > 0){


        gradedByUser ? console.log(gradedByUser) : setGradedByUser(true);
      } 
    }

   

    const deleteConsGrade = async () => {

      const postData = {
        'user': userID,
        'id': routeID,
        'type': type
      }

    
     

      const consGradeValue = await fetch('https://elevationrockgym.com/api/del_grade.php',{
        method: 'POST',
        body: JSON.stringify(postData)
      }).then(function(response) {
        return response.json();
      });

      props.cons_grade(consGradeValue);

      gradedByUser ? setGradedByUser(false) : console.log(gradedByUser);
    }
  
    const handleChange = event => {
      setValue(event.target.value);
    }
  
    const handleSubmit = async (event) => {

      
      event.preventDefault();


      const consGradeValue = await fetch('https://elevationrockgym.com/api/add_grade.php',{
        method: 'POST',
        body: JSON.stringify({
          user: userID,
          id: routeID, 
          grade: value,
          type: type
        })
      }).then(function(response) {
 
        return response.json();
      }); 

      props.cons_grade(consGradeValue);

      await displayGrade();

      setShow(false);
      
  
    }
  

      if((!gradedByUser && JSON.stringify(type) === JSON.stringify("R"))){
      
      return (
        <div>
        <Button variant="warning" onClick={handleShow}>Add Grade</Button>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Enter your grade below!</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form> 
              <Form.Group >
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Your Rating</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={handleChange}>
                      <option></option>
                      <option value="5.6">5.6</option>
                      <option value="5.7">5.7</option>
                      <option value="5.8">5.8</option>
                      <option value="5.9-">5.9-</option>
                      <option value="5.9">5.9</option>
                      <option value="5.9+">5.9+</option>
                      <option value="5.10-">5.10-</option>
                      <option value="5.10">5.10</option>
                      <option value="5.10+">5.10+</option>
                      <option value="5.11-">5.11-</option>
                      <option value="5.11">5.11</option>
                      <option value="5.11+">5.11+</option>
                      <option value="5.12-">5.12-</option>
                      <option value="5.12">5.12</option>
                      <option value="5.12+">5.12+</option>
                      <option value="5.13-">5.13-</option>
                      <option value="5.13">5.13</option>
                      <option value="5.13+">5.13+</option>
                    </Form.Control>
                  </InputGroup>
                </Form.Group>
              <Button variant="primary" onClick={handleSubmit}>
                Submit
              </Button>
            </Form>
        </Modal.Body>
      </Modal>
      </div>

      );
      } else if(!gradedByUser && (JSON.stringify(type) !== JSON.stringify("R"))){
        return (
        <div>
          <Button variant="warning" onClick={handleShow}>Add Grade</Button>
          <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>Enter your grade below!</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Form> 
                <Form.Group >
                  <InputGroup className="mb-2">
                      <InputGroup.Prepend>
                          <InputGroup.Text>Your Rating</InputGroup.Text>
                      </InputGroup.Prepend>
                      <Form.Control as="select" onChange={handleChange}>
                        <option></option>
                        <option value="V0">V0</option>
                        <option value="V1">V1</option>
                        <option value="V2">V2</option>
                        <option value="V3">V3</option>
                        <option value="V4">V4</option>
                        <option value="V5">V5</option>
                        <option value="V6">V6</option>
                        <option value="V7">V7</option>
                        <option value="V8">V8</option>
                        <option value="V9">V9</option>
                        <option value="V10">V10</option>
                        <option value="V11">V11</option>
                        <option value="V12">V12</option>
                        <option value="V13">V13</option>
                        <option value="V14">V14</option>
                        <option value="V15">V15</option>
                      </Form.Control>
                    </InputGroup>
                  </Form.Group>
                <Button variant="primary" onClick={handleSubmit}>
                  Submit
                </Button>
              </Form>
          </Modal.Body>
        </Modal>
      </div>
        );
      

      } else if(gradedByUser ){
        return(
          <div>
            <table>
              <tr>
                <td>
                {userGrades[0].grade}
                </td>
                <td>
                <Button onClick={deleteConsGrade}>
                    DELETE 
                </Button>
                </td>
              </tr>
            </table>

          </div>
        )
      } 

      
    }

  export default ConsGrade;
