import React, {useState, useEffect} from "react";
import { Spinner, Table, Button, ListGroup } from 'react-bootstrap';

import './listTeams.css';

const JoinedTeam = props => {
    const team = props.team;
    const user = props.user;
    const [isJoined,setIsJoined] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false);

    useEffect(()=>{
        fetchMyRequests(user.user_id,team.team_id)
    },[])

    const fetchMyRequests = async (userID,teamID) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_my_requests.php",{
            method: 'POST',
            body: JSON.stringify({
            user_id: userID,
            team_id: teamID   
            })
        }).then(result => result.json())
        .then((result)=>{
            setIsJoined(result)
            setIsLoaded(true)
        })
    }

    const joinTeam = async (teamID,userID) => {
        setIsLoaded(false);
        const data = await fetch("https://elevationrockgym.com/api/join_team.php",{
            method: 'POST',
            body: JSON.stringify({
            team_id: teamID,
            user_id: userID   
            })
        }).then(result => result.text())
        .then((result)=>{
            setIsJoined(true);
            setIsLoaded(true);
        })
    }


    return(
        <div>
            <table className='team_item'>
                <tr>
                    <td>
                        <h1>
                            {team.team}
                        </h1>
                    </td>
                    <td>
                        <h2>
                            {isLoaded ?
                            <Button disabled={isJoined} onClick={()=>joinTeam(team.team_id,user.user_id)} >{isJoined ? 'REQUEST PENDING':'JOIN'}</Button>
                            :
                            <Spinner animation="border" role="status" />
                            }
                            </h2>
                    </td>
                </tr>  
                    
            </table>

        </div>
    )
}

export default JoinedTeam;