import React, {useState, useEffect} from "react";
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'

const ChangeUsername = props => {
    const user = props.user
    console.log(props)

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const [input,setInput] = useState(user.user_name);
    const [updateUsername,setUpdateUsername] = useState(user.user_name);

    const updateNick = async (userID) =>{
        const data = await fetch("https://elevationrockgym.com/api/update_nick.php",{
            method: 'POST',
            body: JSON.stringify({
                user_name: input,
                user_id: userID    
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setUpdateUsername(result);
        });
        handleClose();
    }

    const handleInput = event => {
        setInput(event.target.value)
    }

    return(
        <div>
            <div>
            <p>Hello {updateUsername}</p>
            </div>
            <Button onClick={handleShow} >Update Nickname</Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Update Your Nick Name Below</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <p>This Nickname will be visible in competitions.</p>
                    <InputGroup>
                        <InputGroup.Prepend>
                        <InputGroup.Text>Nickname:</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" onChange={handleInput} value={input} placeholder={user.user_name} />
                    </InputGroup>
                </Modal.Body>

                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={()=>updateNick(user.user_id)}>Save changes</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

export default ChangeUsername;