import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ChangeUsername from "../Profile/changeUser";
import {Bar} from 'react-chartjs-2';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import Card from 'react-bootstrap/Card';
import Accordion from 'react-bootstrap/Accordion';
import Button from 'react-bootstrap/Button'
import FetchUserBreakdown from "../Graphs/fetchUserBreakdown";
import GoldenTicket from "../Forms/GoldenTicket"



const Profile = () => {
  const { user, isAuthenticated } = useAuth0();
  const [userProfile, setUserProfile] = useState([]);
  const [isLoaded,setIsLoaded] = useState(false);

  console.log(user)

  const loadUserProfile = async (userID,userEmail,userNick) => {
    console.log(user)
    const data = await fetch("https://www.elevationrockgym.com/api/fetch_profile.php",{
      method: 'POST',
      body: JSON.stringify({
        user_id: userID,
        user_email: userEmail,
        user_nick: userNick   
      })
    })
    .then(result => result.json())
    .then((result) => {
        console.log(result)
        setUserProfile(result)
        setIsLoaded(true)
      }
    );
  }

  useEffect(()=>{
    if(isAuthenticated){
      loadUserProfile(user.sub,user.email,user.nickname)
    }
  },[isAuthenticated])

  console.log(userProfile)
  if(isAuthenticated && isLoaded ){
    return (
      <div>
        <GoldenTicket user={user.sub} />
        <ChangeUsername user={userProfile} />

        <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="0">
                                Route Ticks
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                              <Tabs defaultActiveKey="bygrade" id="test" >
                                <Tab eventKey="bygrade" title="By Grade">
                                  <FetchUserBreakdown userID={user.sub} option="RG" />
                                </Tab>
                                <Tab eventKey="bydate" title="By Date">
                                  Coming soon
                                </Tab>
                              </Tabs>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Bouder Ticks
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                Coming Soon
                                
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>
      </div>
    )
  } else {
    return(
      <div>
      Loading ...
    </div>
    )
  }
};

export default Profile;