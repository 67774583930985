import React, { Component} from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Routes from '../RouteList/Routes';

class Browse extends Component {
    constructor(props){
        super(props);
    }

    render(){
        return(
            <div>
                <Tabs defaultActiveKey="routes" id="browseList" >
                    <Tab eventKey="routes" title="Routes">
                        <Routes type="R" />
                    </Tab>
                    <Tab eventKey="boulders" title="Upstairs Boulders">
                        <Routes type="B" />
                    </Tab>
                    <Tab eventKey="dboulders" title="Downstairs Boulders">
                        <Routes type="DB" />
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

export default Browse;