import React, {useState, useEffect} from 'react';
import { Spinner, Toast, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';


const WarningCard = props => {

    const [equipmentLogList,setEquipmentLogList] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [error,setError] = useState(null)
    const [show,setShow] = useState(true)
    
    const toggleShow = () => {
        setShow(!show)
    }

    useEffect(()=>{
        viewEquipmentLogs();    
    },[])

    useEffect(()=>{
        viewEquipmentLogs();    
    },[props.refresh])


    const viewEquipmentLogs = async () => {
        const data = await fetch("https://elevationrockgym.com/api/view_warning_logs.php",{
            method: 'POST',
            body: JSON.stringify({
                equipment_id: 'empty'
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setEquipmentLogList(result)
            setIsLoaded(true)
        },
        (error) => {
            setIsLoaded(true)
            setError(error)
        }
        )
    }

    const colorArray = ['','warning','danger'];

    if(isLoaded){
        return(
            <div>
                <Toast show={show} onClose={toggleShow}>
                    <Toast.Header>
                        Equipment Warnings
                    </Toast.Header>
                    
                    <Toast.Body>
                    <ListGroup>
                        {equipmentLogList.map((item =>(
                        
                            <ListGroup.Item variant={colorArray[item.alert]}>
                                {item.last_save} {item.user_name}
                            </ListGroup.Item>

                        )))}
                    </ListGroup>
                    </Toast.Body>
                </Toast>
            </div>
        )
    } else if(!isLoaded){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default WarningCard;