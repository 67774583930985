import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button'
import Card from 'react-bootstrap/Card'
import CardGroup from 'react-bootstrap/CardGroup';
import RecentRoutes from '../Forms/recentRoutes';
import { Link } from 'react-router-dom';
import Toast from 'react-bootstrap/Toast'

function Home() {

  const [show, setShow] = useState(true);
  const toggleShow = () => setShow(!show);
  const [routeList, setRouteList] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [error,setError] = useState(null);



  return(
    <div>
            <CardGroup>
                <Card style={{ width: '18rem' }}>
                    <Link to='/boulderclub' >
                        <Card.Img variant="top" src="https://www.elevationrockgym.com/app/images/bc_logo2.png" />
                    </Link>

                    <Card.Body>
                    <Card.Title>Spring Boulder Club</Card.Title>
                    <Card.Text>
                        Click to enter Spring Boulder Club
                    </Card.Text>
                <Link to='/boulderclub' ><Button variant="primary" >Compete!</Button></Link>
                    </Card.Body>
                </Card> 
            </CardGroup>
    </div>
  )

}



export default Home;