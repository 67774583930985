import React from "react";
import { useHistory } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";

const Auth0ProviderWithHistory = ({ children }) => {
  const domain = 'login.elevationrockgym.com';
  const clientId = 'BiRA810V323cRiruGzDDVEQTFmgJekAa'; //dev-xzdpxgay.us.auth0.com

  console.log(process.env)

  const history = useHistory();

  console.log(window.location.href)

  const onRedirectCallback = (appState) => {
    history.push(appState?.returnTo || window.location.pathname);
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.href}
    >
      {children}
    </Auth0Provider>
  );
};

export default Auth0ProviderWithHistory;