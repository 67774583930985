import React, {useState, useEffect} from 'react';
import { Spinner, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import './listEquipment.css';
import FormControl from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const RetireEquipment = props => {

    const userId = props.userId;
    const item = props.item;
    
    const [retireEquipmentResult, setRetireEquipmentResult] = useState("");
    const [equipmentToRetire,setEquipmentToRetire] = useState([]);


    const [showRetireEquipmentResult, setShowRetireEquipmentResult] = useState(false);
    const handleCloseRetireEquipmentResult = () => setShowRetireEquipmentResult(false);


    const [showRetireEquipment,setShowRetireEquipment] = useState(false);
    const handleCloseRetireEquipment = () => setShowRetireEquipment(false);
    const handleShowRetireEquipment = async (equipment) => {
        setEquipmentToRetire(equipment)
        setShowRetireEquipment(true)
    }

    const retireEquipment = async (equipmentId,identifier,type) => {
        const data = await fetch("https://elevationrockgym.com/api/retire_equipment.php",{
            method: 'POST',
            body: JSON.stringify({
                id: equipmentId,
                identifier: identifier,
                type: type,
                user_id: userId
            })
        })
        .then(result => result.text())
        .then((result) => {
            setRetireEquipmentResult(result);
            setShowRetireEquipment(false)
            setShowRetireEquipmentResult(true);
            props.equipmentQuery();
        }
        );
    }

    


    return(
        <div>
            <Button onClick={()=>handleShowRetireEquipment([item['id'],item['identifier'],item['type']])}>Retire {item['type']}</Button>
        
            <Modal show={showRetireEquipment} onHide={handleCloseRetireEquipment}>
                <Modal.Header closeButton>
                    <Modal.Title>Retire {equipmentToRetire[2]}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to retire {equipmentToRetire[2]} {equipmentToRetire[1]}?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={()=>retireEquipment(equipmentToRetire[0],equipmentToRetire[1],equipmentToRetire[2])}>Retire</Button>
                    <Button variant="secondary" onClick={handleCloseRetireEquipment}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showRetireEquipmentResult} onHide={handleCloseRetireEquipmentResult}>
                <Modal.Header closeButton>
                    <Modal.Title>Retire {equipmentToRetire[2]} Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {retireEquipmentResult}
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button variant="secondary" onClick={handleCloseRetireEquipmentResult}>Close</Button>
                </Modal.Footer>
            </Modal>
        
        </div>
    )

}
export default RetireEquipment;