import React, {useState, useEffect} from "react";
import { Table, Accordion,Card, Button, Spinner } from "react-bootstrap";


const EverestLeaderboard = props => {

    const [leaderboard,setLeaderboard] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [usernamesLoaded, setUsernamesLoaded] = useState(false);
    const [usernames,setUsernames] = useState([])

    useEffect(()=>{
        fetchLeaderboard();
        fetchUsernames();
    },[])

    

    /// Fetch Leaderboard //
    const fetchLeaderboard = async () => {
        setIsLoaded(false)
    
        await fetch("https://elevationrockgym.com/api/fetch_everest_leaderboard.php",{
            method: 'POST'
        }
        ).then(result => result.json())
          .then(
            (result) => {
              setLeaderboard(result);
              setIsLoaded(true)
            }
        )
              
    }

    const fetchUsernames = async () => {
        setIsLoaded(false)
    
        await fetch("https://elevationrockgym.com/api/fetch_usernames.php",{
            method: 'POST'
        }
        ).then(result => result.json())
          .then(
            (result) => {
              setUsernames(result);
              setUsernamesLoaded(true)
            }
        )
              
    }

    if(isLoaded && usernamesLoaded){

        return(
            <div>
                
                    <Card>
                        
                       
                        <Card.Body>
                        
                        <Table striped bordered hover>
                            <thead>
                                <tr>
                                    <th>Name</th>
                                    <th># Of Routes</th>
                                    <th>Vertical Feet</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leaderboard.map((item)=>(
                                    <tr>
                                        <td>
                                            {usernames[item.user_id]}
                                        </td>
                                        <td>
                                            {item[`route_total`]}
                                        </td>
                                        <td>
                                            {item[`points`]}
                                        </td>
                                    </tr>
                                ))}
            
                            </tbody>
                        </Table>
                            
                            
                        </Card.Body>
                    
                    </Card>
                    
                
            </div>
        )

    } else {

        return(
            <div>
                <Spinner animation="border" role="status" /> 
            </div>
        )
    }
  


}

export default EverestLeaderboard;