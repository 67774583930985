import React, {useState, useEffect} from "react";
import { Spinner } from 'react-bootstrap';

import './boulderClubScorecard.css'



const GridItem = props => {

    const image1 = 'https://elevationrockgym.com/app/images/logo.png';
    const image2 = 'https://elevationrockgym.com/app/images/spare.png'
    const image3 = 'https://elevationrockgym.com/app/images/strike.png';
    const images = [image1,image2,image3]
    const item = props.item;
    const userID = props.userID;
    const week = props.week;

    const [scoreLoading,setScoreLoading] = useState(false);
    const [userScorecard,setUserScorecard] = useState(props.userScorecard);


    const handleClick = async (ind,points,week) => {
        const prevState = userScorecard;
        const newInd = (Number(userScorecard.score_id[ind])+1)%3;
        prevState.score_id[ind] = JSON.stringify(newInd);
        setScoreLoading(true);
        console.log(scoreLoading)
        const data = await fetch("https://elevationrockgym.com/api/save_scorecard.php",{
          method: 'POST',
          body: JSON.stringify({
            score_id: prevState.score_id,
            user_id: userID,
            ind: newInd,
            points: points,
            week: week     
          })
        })
        .then( result => result.json() )
        .then( (result)=> {
            prevState.total_score = result;
            props.totalScore(result);
            setUserScorecard(prevState);
            setScoreLoading(false);
        }
        );
      }

    if(scoreLoading){
        return(
            <a key={item.ind} onClick={()=> handleClick(item.ind,item.points,week)}>
                    <div key={item.ind} className="grid-item" >
                        <Spinner animation="border" role="status" />
                    </div>
            </a>
        )
    } else if(!scoreLoading) {
        return(
            
            <a key={item.ind} onClick={()=> handleClick(item.ind,item.points,week)}>
                <div key={item.ind} className="grid-item" style={{ backgroundImage:`url(${images[userScorecard.score_id[item.ind]]})` }}>{item.points}</div>
            </a>
        )
    }
}

export default GridItem;