import React, {useState, useEffect} from "react";
import { Spinner } from "react-bootstrap";

const CreateTeam = props => {
    const user = props.user
    console.log(props)
    const [input,setInput] = useState(" ");
    const [isLoading, setIsLoading] = useState(false);
    const [isSubmitted,setIsSubmitted] = useState(false);

    const createTeam = async (userID) =>{
        setIsLoading(true);
        const data = await fetch("https://elevationrockgym.com/api/create_team.php",{
            method: 'POST',
            body: JSON.stringify({
                team_name: input,
                user_id: userID    
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setIsSubmitted(true);
            setIsLoading(false);
            props.updateUser(userID);
        });
    }

    const handleInput = event => {
        setInput(event.target.value)
    }
    if(!isLoading && !isSubmitted){
        return(
            <div>
                <input onChange={handleInput} />
                <button onClick={()=>createTeam(user.user_id)} >Create Team</button>
            </div>
            
        )    
    } else if(isLoading || isSubmitted) {
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default CreateTeam;