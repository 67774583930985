import React, {useState, useEffect} from 'react';
import { Link } from 'react-router-dom';
import LogoutButton from '../Login/logoutButton';
import LoginButton from '../Login/loginButton';
import { useAuth0 } from "@auth0/auth0-react";

import './SideDrawer.css';

const SideDrawer = props => {



    const {user, isAuthenticated } = useAuth0();
    const [userProfile, setUserProfile] = useState([]);
    const [isLoaded, setIsLoaded ] = useState(false);
    const [isPermit,setIsPermit]  = useState(false);


    useEffect(()=>{
        console.log("hel")
        if(isAuthenticated){
            checkStatus(user.sub,user.email,user.nickname)
        }
    },[isAuthenticated]);


    let drawerClasses = ['side_drawer'];
    if (props.show) {
        drawerClasses ='side_drawer open';
    }

    useEffect(()=>{
        if(isLoaded){
            if(userProfile.permissions >= 1){
                setIsPermit(true)
            }
        }
    },[isLoaded])

    const checkStatus = async (userID, userEmail, userNick) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userID,
                user_email: userEmail,
                user_nick: userNick   
            })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result)
            setIsLoaded(true)
        }
        );
    }


    return(
        <nav className={drawerClasses}>
            <ul>
                <li><Link to="/home">Home</Link></li>
                <li><Link to="/browse">Browse</Link></li>
                <li><Link to="/map">Map</Link></li>
                <li><Link to="/competitions">Competitions</Link></li>
                <li>{isAuthenticated ? <Link to="/profile">Profile</Link> : ''}</li>
                <li>{isPermit ? <Link to="/equipment">Equipment</Link> : ''}</li>
                <li>{isAuthenticated ? <LogoutButton /> : <LoginButton />}</li>
            </ul>
        </nav>
    )
};

export default SideDrawer;