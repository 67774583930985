import React, {useState, useEffect} from "react";
import AddRoute from "../Forms/addRoute";
import Spinner from "react-bootstrap/Spinner";

const QREdit = props => {

    const [userProfile, setUserProfile] = useState([]);
    const [isLoaded, setIsLoaded ] = useState([]);
    const user = props.user;
    const qrID = props.qrID;

    useEffect(()=>{
        checkStatus(user.sub,user.email,user.nickname)

    },[]);

    const checkStatus = async (userID, userEmail, userNick) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
      method: 'POST',
      body: JSON.stringify({
        user_id: userID,
        user_email: userEmail,
        user_nick: userNick   
      })
    })
    .then(result => result.json())
    .then((result) => {
        console.log(result)
        setUserProfile(result)
        setIsLoaded(true)
      }
    );
    }
    if(isLoaded && userProfile.permissions == 1){
        return (
            <div>
                <AddRoute user={userProfile} qrID={qrID} />
            </div>
        )
    } else if(isLoaded && userProfile.permissions ==0){
        return (
            <div>
                QR Unassigned
            </div>
        )

    } else {
        return (
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }


}

export default QREdit;