import React, {useState, useEffect} from "react";
import { Spinner } from 'react-bootstrap';
import GridItem from './gridItem';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import InputGroup from 'react-bootstrap/InputGroup';
import Form from 'react-bootstrap/Form';

import './boulderClubScorecard.css'
import image1 from '../../images/logo.png'
import image2 from '../../images/spare.png'
import image3 from '../../images/strike.png'

const BoulderClubScoreCard = props => {
  const [isLoaded,setIsloaded] = useState(false);
  const [scorecard, setScorecard] =useState([]);
  const [isUserLoaded,setIsUserLoaded] = useState(false);
  const [userScorecard, setUserScorecard] =useState([]);
  const [week, setWeek] = useState();
  const [weekChange,setWeekChange] = useState('1');
  const [show,setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

 

  const images = [image1,image2,image3];

  const userID = props.user.user_id;
  const user = props.user;
  const permissions = props.permissions;

  console.log(user);

  const fetchWeek = async () => {
    const data = await fetch("https://elevationrockgym.com/api/fetch_bc_week.php")
    .then(result => result.json())
      .then(
        (result) => {
          setWeek(result)
          console.log(result)
        }
      )
  }
  
  const updateWeek = async () => {
    const data = await fetch("https://elevationrockgym.com/api/update_week.php",{
      method: 'POST',
      body: JSON.stringify({
        week: weekChange     
      })
    })
    .then(result=>result.text())
    .then(
      (result)=>{
        setWeek(result);
        console.log(result)
      }
    )      
  }


  const fetchUserScorecard = async (userID,week) => {
    const data = await fetch("https://elevationrockgym.com/api/user_scorecard.php",{
      method: 'POST',
      body: JSON.stringify({
        user: userID,
        week: week     
      })
    }).then(result => result.json())
      .then(
        (result) => {
          setUserScorecard(result)
          setIsUserLoaded(true)
        }
      )
  }

  const fetchScorecard = async (week) => {
    const data = await fetch("https://elevationrockgym.com/api/fetch_scorecard.php",{
      method: 'POST',
      body: JSON.stringify({
        week: week     
      })
    }).then(result => result.json())
    .then((result) => {
        setScorecard(result)
        setIsloaded(true)
      }
    );
  }

  const setTotalScore = async (userTotalScore) => {
    const prevState = userScorecard;
    prevState.total_score = userTotalScore;
    console.log(prevState.total_score)
    setUserScorecard(prevState);
    fetchScorecard(week);
    props.updateUser(userID);
  }

  const handleWeekChange = event => {
    setWeekChange(event.target.value);
    console.log(weekChange)
  }
  
  
  useEffect(()=>{
    fetchWeek();
  },[user]);

  useEffect(() => {
    if(week){ 
      fetchScorecard(week);
      fetchUserScorecard(userID, week);
    }
  },[week]);
 
    return(
      userScorecard && isLoaded && isUserLoaded && (
        <div>
          <div className='score_header'>
            <table>
              <tr>
                <td>
                <img src='https://elevationrockgym.com/app/images/defn.png' width='50%'/>
                </td>
                <td>
                  <p><strong>Week {week} {permissions ==='1' || permissions ==='2' ? <Button onClick={handleShow}>{`EDIT`}</Button>: null} Total:</strong>  {userScorecard.total_score}</p>
                </td>
              </tr>
            </table>
          </div>
          <div className="grid-container">
            {scorecard.map((item)=> (
              <GridItem userScorecard={userScorecard} userID={userID} item={item} totalScore={setTotalScore} week={week}/>
            ))}
          </div>
          <Modal show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                  <Modal.Title>Change Boulder Club Week</Modal.Title>
              </Modal.Header>
              <Modal.Body>
              
                <InputGroup className="mb-2">
                  <InputGroup.Prepend>
                      <InputGroup.Text>Color</InputGroup.Text>
                  </InputGroup.Prepend>
                  <Form.Control as="select" onChange={handleWeekChange}>
                      <option>{weekChange}</option>
                      <option>2</option>
                      <option>3</option>
                      <option>4</option>
                      <option>5</option>
                      <option>6</option>
                  </Form.Control>
                </InputGroup>
              </Modal.Body>
              <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>Close</Button>
                  <Button variant="primary" onClick={updateWeek}>Update Week</Button>
              </Modal.Footer>
            </Modal>
        </div>
      )
    )
};

export default BoulderClubScoreCard;