import React, {useState, useEffect} from "react";
import { Spinner, Table, Button, ListGroup } from 'react-bootstrap';

import './listTeams.css';

const FetchTeamRequests = props => {
    const team = props.team;
    const [requestList, setRequestList] = useState([]);
    const [isLoaded, setIsLoaded] =useState(false);
    const [fetchUsers,setFetchUsers] = useState([]);
    const [fetchUsersIsLoaded,setFetchUsersIsLoaded] = useState(false);
    const [isApproved, setIsApproved] = useState([]);

    useEffect(()=>{
        fetchUserInfo();
        fetchUserRequests(team.team_id);
    },[])

    const fetchUserInfo = async () => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_user_info.php")
        .then(result => result.json())
        .then(
            (result) => {
                setFetchUsers(result);
                setFetchUsersIsLoaded(true);
            }
        )
    }

    const fetchUserRequests = async (teamID) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_team_requests.php",{
            method: 'POST',
            body: JSON.stringify({
            team_id: teamID   
            })
        }).then(result => result.json())
        .then((result)=>{
            setRequestList(result);
            setIsLoaded(true);
            result.map( item => {
                const prevState = isApproved;
                prevState[item.user_join] = false;
                setIsApproved(prevState);
            })
        })
    }

    const approveRequest = async (teamID,userJoinID) => {
        const data = await fetch("https://elevationrockgym.com/api/approve_team_request.php",{
            method: 'POST',
            body: JSON.stringify({
            user_join_id: userJoinID,
            team_id: teamID   
            })
        }).then(result => result.text())
        .then((result)=>{
            console.log(result);
            const prevState = isApproved;
            prevState[userJoinID] = true;
            setIsApproved(prevState);
            fetchUserRequests(teamID);
        })
    }

    if(isLoaded && fetchUsersIsLoaded){
        return(
            <div>
                <ListGroup >
                    {requestList.map((item =>(
                        <ListGroup.Item >
                            <table className='team_item'>
                                <tr>
                                    <td>
                                        <h1>
                                        {fetchUsers[item.user_join].user_name} wants to join your team!
                                        </h1>
                                    </td>
                                    <td>
                                        <h2>
                                        {isApproved[item.user_join] ?
                                            <Button  >REQUEST APPROVED</Button>
                                            :
                                            <Button onClick={()=>approveRequest(team.team_id,item.user_join)} >APPROVE</Button>
                                            }
                                        </h2>
                                    </td>
                                </tr>  
                                    
                            </table>
                        </ListGroup.Item>
                    )))}
                </ListGroup>

            </div>
        )
    } else {
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default FetchTeamRequests;