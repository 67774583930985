import React, {useState, useEffect} from 'react';
import { Spinner, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import './listEquipment.css';
import FormControl from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import AddEquipment from './addEquipment';
import RetireEquipment from './retireEquipment';
import EditEquipment from './editEquipment';
import ViewEquipmentLog from './viewEquipmentLogs';

const Gym = props => {

    const userId = props.user.user_id;

    const [equipmentQuery,setEquipmentQuery] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [showEquipmentLogs,setShowEquipmentLogs] = useState(false);
    const [equipmentType, setEquipmentType] = useState(null);
    const [equipmentId,setEquipmentId] = useState(null);
    const [fullscreen,setFullscreen] = useState(true)

    const handleCloseEquipmentLogs = () => {
        setShowEquipmentLogs(false);
    }

    const handleOpenEquipmentLogs = (id,type) => {
        setEquipmentType(type);
        setEquipmentId(id);
        setShowEquipmentLogs(true);
    }

    useEffect(()=>{
        
        queryEquipment();
    },[])


    const queryEquipment = async () => {
        const data = await fetch("https://elevationrockgym.com/api/query_equipment.php",{
            method: 'POST'
        })
        .then(result => result.json())
        .then((result)=>{
            setEquipmentQuery(result);
            setIsLoaded(true);
        });
    }


    const pushReload = () => {
        props.reload();
    }


    if(!isLoaded){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    } else {
        return(
            <div>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="0">
                                AutoBelays
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                            <AddEquipment type='Autobelay' userId={userId} equipmentQuery={queryEquipment} />
                                <ListGroup>
                                    {equipmentQuery['Autobelay'].map(item=>(
                                        <ListGroup.Item>
                                            <table className='equip_item'>
                                                <tr>
                                                    <td>
                                                        {item['identifier']}
                                                    </td>
                                                    <td>
                                                        <h2>    
                                                                        <ViewEquipmentLog  reload={pushReload} user={props.user} equipmentQuery={queryEquipment} equipmentId={item['id']} equipmentType={item['type']} />
                                                                        <RetireEquipment userId={userId} equipmentQuery={queryEquipment} item={item}/>
                                                                        <EditEquipment userId={userId} equipmentQuery={queryEquipment} equipmentId={item['id']} equipmentType={item['type']} equipmentIdentifier={item['identifier']} equipmentDate={item['date']} equipmentDescription={item['description']} />
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </table>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>                               
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Ropes
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <AddEquipment type='Rope' userId={userId} equipmentQuery={queryEquipment} />
                                <ListGroup>
                                    {equipmentQuery['Rope'].map(item=>(
                                        <ListGroup.Item>
                                            <table className='equip_item'>
                                                <tr>
                                                    <td>
                                                        {item['identifier']}
                                                    </td>
                                                    <td>
                                                        <h2>    
                                                                        <ViewEquipmentLog  reload={pushReload} user={props.user} equipmentQuery={queryEquipment} equipmentId={item['id']} equipmentType={item['type']} />
                                                                        <RetireEquipment userId={userId} equipmentQuery={queryEquipment} item={item}/>
                                                                        <EditEquipment userId={userId} equipmentQuery={queryEquipment} equipmentId={item['id']} equipmentIdentifier={item['identifier']} equipmentDate={item['date']} equipmentDescription={item['description']} />
                                                        </h2>
                                                    </td>
                                                </tr>
                                            </table>
                                        </ListGroup.Item>
                                    ))}
                                </ListGroup>


                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Draws
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="2">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Bolts
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="3">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                HVAC
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="4">
                            <Card.Body>

                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>

            </div>
        )
    }
}

export default Gym;