import React, {useState, useEffect} from "react";
import { Spinner, Table, Button, ListGroup } from 'react-bootstrap';
import JoinedTeam from './joinedTeam';

import './listTeams.css';

const ListTeams = props => {

    const user = props.user;
    const [teamList, setTeamList] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [error,setError] = useState(null);
    const [isClicked, setIsClicked] = useState([]);
    const [isJoin, setIsJoin] = useState([]);
    const userID = user.user_id;


    useEffect(()=>{
        fetchTeams();
    },[])



    const fetchTeams = async () => {
    
        const data = await fetch("https://elevationrockgym.com/api/fetch_teams.php",{method: "POST"})
          .then(result => result.json())
          .then(
            (result) => {
              setTeamList(result)
              setIsLoaded(true)
                result.map( item => {
                    const prevState = isClicked;
                    prevState[item.team_id] = false;
                    setIsClicked(prevState);

                    const prevStateT = isJoin;
                    prevStateT[item.team_id] = 'JOIN';
                    setIsJoin(prevStateT);
                })
            
            },
            (error) => {
              setIsLoaded(true)
              setError(error)
            }
            )   
    }

    console.log(teamList)
 
    if(!isLoaded){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    } else if(isLoaded){
        return(
            <div>
                <ListGroup>
                    {teamList.map((item =>(
                        <ListGroup.Item>
                            <JoinedTeam user={user} team={item} />
                        </ListGroup.Item>
                    )))}

                </ListGroup>
            </div>
        )
    } else if (!isLoaded){
        return (
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default ListTeams;