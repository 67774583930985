import React, {useState, useEffect} from "react";
import {Bar, Scatter} from 'react-chartjs-2';
import {Tabs, Tab, Button} from 'react-bootstrap';
import Spinner from 'react-bootstrap/Spinner';



const FetchUpAllNightBreakdown = props => {
    const userID = props.userID;

    const [reload,setReload] = useState(0);
    const [option, setOption] = useState(props.option);
    const [isLoaded,setIsLoaded] = useState(false);
    const [userGradeBreakdown,setUserGradeBreakdown] = useState([]);
    const [userDateBreakdown,setUserDateBreakdown] = useState([]);


    useEffect(()=>{
      if(props.push){
        fetchUserTicks();
      }
      
    },[props.push])

    useEffect(()=>{
      fetchUserTicks();
    },[reload])


    const fetchUserTicks = async () => {

      console.log('userTICKS fetched')
        
        const data = await fetch('https://elevationrockgym.com/api/fetch_upallnight_breakdown.php',{
          method: 'POST',
          body: JSON.stringify({
            user_id: userID,
            option: option
        })
        }).then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setUserGradeBreakdown(result.grade)
            setUserDateBreakdown(result.date)
            setIsLoaded(true);
        }
        )
     }
      
      const options = {
        scales: {
          yAxes: [
            {
              stacked: true,
              ticks: {
                beginAtZero: true,
              },
            },
          ],
          xAxes: [
            {
              stacked: true,
            },
          ],
        },
      }

      const gradeEquiv = ["5.6","5.6+","5.7-","5.7","5.7+","5.8-","5.8","5.8+","5.9-","5.9","5.9+","5.10-","5.10","5.10+","5.11-","5.11","5.11+","5.12-","5.12","5.12+","5.13-","5.13","5.13+"];


      const options2 = {
        scales: {
          yAxes: [{
              ticks: {
                  beginAtZero:true,
                  min: 0,
                  max: 30,
                  callback: function(value, index, values) {
                    return gradeEquiv[value];
                  }  
              }
            }],
          xAxes: [{
            label: 'hour',
            ticks: {
                beginAtZero:true,
                min: 0,
                max: 13    
            }
          }]
         }
      };
    

      if(isLoaded && userGradeBreakdown && userDateBreakdown){
          return(
            <div>
              <Button onClick={()=>setReload(reload+1)}>Reload</Button>
                <Tabs defaultActiveKey="bygrade" id="test" >
                  <Tab eventKey="bygrade" title="By Grade">
                    <Bar data={userGradeBreakdown} options={options}/>
                  </Tab>
                  <Tab eventKey="bydate" title="By Time">
                    <Scatter data={userDateBreakdown} options={options2}/>
                  </Tab>
                </Tabs>
            </div>
          )
      } else {
          return(
              <div>
                <Spinner animation="border" role="status" />    
              </div>
          )
        
      }
}

export default FetchUpAllNightBreakdown;