import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const ViewTicks = props => {

    const userID = props.userID;
    const routeID = props.routeID;
    const [value, setValue] = useState(props.grade);
    const [userTicks, setUserTicks] = useState([]);
    const [error,setError] = useState(null);


    const [show, setShow] = useState(false);
    const [isLoaded,setIsLoaded] = useState(false);

    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    useEffect(()=>{
        fetchUserticks();
    },[show])


    
    const handleChange = async (event) => {
        setValue(event.target.value)
    }

    const deleteTick = async (tickID) => {

        
       

        const postData = {
          'tick_id': tickID,
          'user_id': userID
        }
  
      
       
  
        const updateTicklist = await fetch('https://elevationrockgym.com/api/del_tick.php',{
          method: 'POST',
          body: JSON.stringify(postData)
        })

        fetchUserticks();
    }




    const fetchUserticks = async () => {

  
        
        const data = await fetch('https://elevationrockgym.com/api/fetch_user_ticks.php',{
          method: 'POST',
          body: JSON.stringify({
            user: userID,
            id: routeID
        })
        }).then(result => result.json())
        .then(
        (result) => {

            setUserTicks(result)
            setIsLoaded(true);
        },
        (error) => {
            setError(error)
            setIsLoaded(true);
        }
        )
     }
  



    
    if(isLoaded){
        return(
            <div>
                <Button variant="warning" onClick={handleShow} >View Ticks</Button> 
                <Modal show={show} onHide={handleClose}>
                    <Modal.Header closeButton>
                        <Modal.Title>My Ticks</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {console.log(userTicks)}
                            {userTicks.map((item =>(
                                <ListGroup.Item>
                                    <table>
                                        <tr>
                                            <td>
                                                {item.timestamp}
                                            </td>
                                            <td>
                                                {item.style}
                                            </td>
                                            <td>
                                                <Button onClick={()=>{deleteTick(item.tick_id)}} >
                                                    Delete

                                                </Button>
                                            </td>
                                        </tr>
                                    </table>
                                    
                                </ListGroup.Item>
                            )))}
                        </ListGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>Close</Button>
                    </Modal.Footer>
                </Modal>       
            </div>
        )
    } else if(!isLoaded) {
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }


}

export default ViewTicks;