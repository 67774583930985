import React, { useState, useEffect, Component} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {Spinner, Tabs, Tab,Container, Row, Col, Image} from 'react-bootstrap';
import BoulderClubScorecard from '../BoulderClub/boulderClubScoreCard';
import JoinTeam from '../BoulderClub/joinTeam';
import Leaderboard from '../BoulderClub/leaderboard';
import LoginButton from '../Login/loginButton';
import BoulderClubScoreCard from '../BoulderClub/boulderClubScoreCard';

const BoulderClub = props =>{
 
    useEffect(() => {
        window.scrollTo(0, 0);
        if(isAuthenticated && !isChecked){
            checkBoulderClub(user.sub);
            checkStatus(user);
        }
      });
     

    const [isChecked, setIsChecked] = useState(false);
    const [userProfile,setUserProfile] = useState([]);
    const [statusComplete,setStatusComplete] = useState(false);


    const { user, isAuthenticated } = useAuth0();


    const [joinedBoulderClub, setJoinedBoulderClub] = useState(false);

    const joinBoulderClub = async (user) => {
        const userID = user.sub;
        const userEmail = user.email;
        const userNick = user.nickname;
        const data = await fetch("https://elevationrockgym.com/api/join_boulder_club.php",{
            method: 'POST',
            body: JSON.stringify({
                user: userID,
                email: userEmail,
                nick: userNick
            })
        })
        .then( result => result.json())
        .then((result) => {
            console.log(result)
            setJoinedBoulderClub(result)
            setIsChecked(true)
        }
        )

    }

    const checkStatus = async (user) => {
        const userID = user.sub;
        const userEmail = user.email;
        const userNick = user.nickname;
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
          method: 'POST',
          body: JSON.stringify({
            user_id: userID,
            user_email: userEmail,
            user_nick: userNick   
          })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result);
            setStatusComplete(true);
          }
        );
      }

    const checkBoulderClub = async () => {
        const data = await fetch("https://elevationrockgym.com/api/check_boulder_club.php",{
            method: 'POST',
            body: JSON.stringify({
                user: user.sub   
            })
        })
        .then( result => result.json())
        .then(
            (result) => {
            console.log(result);
            setJoinedBoulderClub(result)
            setIsChecked(true);
            }
        )
    }



    if(isAuthenticated && !joinedBoulderClub && isChecked){
        return(
           <div>
               <p>
                    You have not joined Boulder Club  
                </p>
               <button onClick={() => joinBoulderClub(user)} >Join Now</button>
           </div> 
        )
    } else if(isAuthenticated && !joinedBoulderClub && !isChecked){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }else if(isAuthenticated && joinedBoulderClub && isChecked && statusComplete){
        return(
            <div>
    
                <Tabs defaultActiveKey="info" id="boulderClub" >
                    <Tab eventKey="info" title="Info">
                    <div>
                    Join us for Spring Boulder Club! The rules are simple and its free for members!   
                    </div>
                    <div>
                        <strong>Information</strong>
                        <ul>
                            <li>Starts Tuesday, March 4th </li>
                            <li>1st place team and individual will receive a prize</li>
                            <li>Intermediate ( V4 and below ) and Advanced (V5 and above) Categories!</li>
            
                            <li>End of season party with raffle prizes and food!</li>
                            <li>Every week you participate in earns you one raffle ticket</li>
                            <li></li>
                        </ul>
                    </div>
                    <div>
                        <strong>Rules</strong>
                        <ul>
                            <li>You can have team of up to 3 people or go solo!</li>
                            <li>Every Tuesday, we'll set 10-15 new problems. Your team has until the following Tuesday to score problems</li>
                            <li>Each member of a team will score their top 5 problems and add them together for a total score</li>
                            <li>Only your top 5 weeks count towards your total</li>
                            <li>Flashes earn you 25% more points.</li>
                            <li>Mark your send as a flash if you completed the problem without falling on your first attempt</li>
                            <li>If it took you more than one attempt to send the problem, mark the problem as a redpoint</li>
                        </ul>
                    </div>
                    <div>
                        <strong>Registration</strong>
                        <ul>
                            <li>If you made it to this page, you are registered for Boulder Club</li>
                            <li>To register or join a team, click the tab labeled 'Join Team'.</li>
                            <li>Join team requests must be approved by a member of a team.  Requests are found by clicking the 'Join Team' tab</li>
                            <li>If you need any scores entered from another week, please contact brian@elevationrockgym.com </li>
                            <li>Relay any issues to brian@elevationrockgym.com</li>
                        </ul>
                    </div>
                    </Tab>
                    <Tab eventKey="scorecard" title="ScoreCard">
                        <BoulderClubScoreCard permissions={userProfile.permissions} user={joinedBoulderClub} updateUser={checkBoulderClub}/>
                    </Tab>
                    <Tab eventKey="leaderboard" title="Leaderboard">
                        <Leaderboard user={joinedBoulderClub}/>
                    </Tab>
                    <Tab eventKey="teams" title="Join Team">
                        <JoinTeam user={joinedBoulderClub} updateUser={checkBoulderClub} />
                    </Tab>
                </Tabs>
            </div>
            
        )   
    } else {
        return(
            <div>
                <div>
                    You must login to view this page. Click below to log in or register an account.
                </div>
                <LoginButton />
            </div>
        )
    }
   
}

export default BoulderClub;
