import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';

const UnSetRoute = props => {

    const routeID = props.routeID;
    const userID = props.user.sub;

    
    const unSet = async (routeID,userID) => {
        const data = await fetch("https://elevationrockgym.com/api/del_route.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userID,
                route_id: routeID
            })
        })
        .then(result => result.text())
        .then((result) => {


        }
        );

        window.location.reload(false);
    }


    return(
        <div>
            <Button onClick={() => unSet(routeID,userID)} >Strip Route</Button>          
        </div>
    )
}


export default UnSetRoute;