import React, {useState, useEffect} from "react";
import { useAuth0 } from "@auth0/auth0-react";
import ConsGrade from '../Forms/ConsGrade';
import RouteMap from '../Map/RouteMap';
import './routeDetail.css';
import AddTick from "../Forms/AddTick";
import QRAdmin from "./qrAdmin";
import QREdit from "./qrEdit";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal"



function RouteDetail({ match }) {
  useEffect(() => {
    fetchItem();
  },[]);


  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const { user, isAuthenticated } = useAuth0();


  const [itemDetail, setItemDetail] = useState();
  const [error, setError] = useState();
  const [isLoaded, setIsloaded] = useState();
  const [cgrade, setCgrade] = useState();

  console.log(itemDetail);
  function update(value){
    
    console.log('success')
    if(JSON.stringify(value) !== JSON.stringify(cgrade)){
      setCgrade(value);
    }
  }


  const fetchItem = async () => {
    
   
    const qrid = `qr`+ match.params.id;

    const data = await fetch("https://elevationrockgym.com/api/fetch_route.php",{
      method: 'POST',
      body: JSON.stringify({
        qr_id: match.params.id 
      })
    })
      .then(result => result.json())
      .then(
        (result) => {
          const routeData = result[0];
          if(typeof(routeData) !== 'undefined'){
            setCgrade(routeData.cgrade)
          }
          setItemDetail(routeData);
          setIsloaded(true);
        },
        (error) => {
          setIsloaded(true);
          setError(error)
        }
      )
          
  }

    
  if (error) {
    return <div>Error: {error.message}</div>;
  } else if (!isLoaded) {
    return <div>Loading...</div>;
  } else if (isLoaded && typeof(itemDetail) !== "undefined") {
  
    const itemDetailId = [itemDetail.id];
  
    return(
      <div className="route_detail">
       {isAuthenticated ? 
            <QRAdmin user={user} route={itemDetail} /> 
           : "" 
          }
          <Card>
            <Card.Header as="h5">{itemDetail.name.split('_').join(' ')}</Card.Header>
            <Card.Body>
              <Card.Title>
                <strong>
                {cgrade}
                </strong></Card.Title>
              <Card.Text>
                <table>
                  <tr>
                    <td>Setter:  </td>
                    <td>{itemDetail.setter.split('_').join(' ')}</td>
                  </tr>
                  <tr>
                    <td>
                      Color:  
                    </td>
                    <td>
                      {itemDetail.color}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Set On:  
                    </td>
                    <td>
                      {itemDetail.date}
                    </td>
                  </tr>
                </table>
                <Button onClick={handleShow}>View On Map</Button>
              </Card.Text>

              <Card
                bg={'success'}
                text={'white'}
                className="mb-2"
              >
                <Card.Header>
                  You And This Route
                </Card.Header>
                <Card.Body>
                  <Card.Text>
                    <table>
                      <tr>
                        <td>Your Rating:  </td>
                        <td>
                        {isAuthenticated ?  
                        <ConsGrade userID={user.sub} route_id={itemDetail.id} qr_id={itemDetail.qr} type={itemDetail.type} grade={itemDetail.grade} cons_grade={update}/>
                        :
                        'Please Log In to use this feature!'
                        }
                        </td>
                      </tr>
                      <tr>
                        <td>
                          Your Ticks:
                        </td>
                        <td>
                          {isAuthenticated ?    
                          <AddTick userID={user.sub} route={itemDetail} grade={cgrade}/>
                          :
                          'Please Log In to use this feature!'
                          }
                        </td>
                      </tr>
                    </table>
                  </Card.Text>
                </Card.Body>
              </Card>

              
            </Card.Body>
        </Card>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Map</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <RouteMap searchOptions={{id: itemDetailId}} type={itemDetail.type} />
            <Button variant="primary" onClick={handleClose}>
              Close
            </Button>

        </Modal.Body>
      </Modal>
      </div>
      
    )
  } else {
    return(
      <div>
      {isAuthenticated ? <QREdit user={user} qrID={match.params.id} /> : "Not Logged In..."}
      </div>  
    )
  }
}

export default RouteDetail;