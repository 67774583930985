import React, {useState, useEffect} from 'react';
import DrawerToggleButton from '../SideDrawer/DrawerToggleButton';
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";

import './Toolbar.css';
import LogoutButton from '../Login/logoutButton';
import LoginButton from '../Login/loginButton';

const Toolbar = props => {
    
    const { user, isAuthenticated } = useAuth0();
    const [userProfile, setUserProfile] = useState([]);
    const [isLoaded, setIsLoaded ] = useState(false);
    const [isPermit,setIsPermit]  = useState(false);


    useEffect(()=>{
        console.log("hel")
        if(isAuthenticated){
            checkStatus(user.sub,user.email,user.nickname)
        }
    },[isAuthenticated]);

    useEffect(()=>{
        if(isLoaded){
            if(userProfile.permissions >= 1){
                setIsPermit(true)
            }
        }
    },[isLoaded])

    const checkStatus = async (userID, userEmail, userNick) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userID,
                user_email: userEmail,
                user_nick: userNick   
            })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result)
            setIsLoaded(true)
        }
        );
    }

 
    
    return (
    <header className="toolbar">
        <nav className="toolbar_navigation">
            <div className="toolbar_toggle_button">
                <DrawerToggleButton click={props.drawerClickHandler} />
            </div>
            <div className="toolbar_logo"><a href="https://www.elevationrockgym.com/app"><img src="https://elevationrockgym.com/app2/logo2.png" alt="Elevation Rock Gym" /></a></div>
            <div className="spacer" />
            <div className="toolbar_nav_item">
                <ul>
                    <li><Link to="/home">Home</Link></li>
                    <li><Link to="/browse">Browse</Link></li>
                    <li><Link to="/map">Map</Link></li>
                    <li><Link to="/competitions">Competitions</Link></li>
                    <li>{isAuthenticated ? <Link to="/profile">Profile</Link> : ''}</li>
                    <li>{isPermit ? <Link to="/equipment">Equipment</Link> : ''}</li>
                    <li>{isAuthenticated ? <LogoutButton /> : <LoginButton />}</li>
                </ul>
            </div>
        </nav>
    </header>
)};

export default Toolbar;
