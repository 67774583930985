import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import { Form, ToggleButton,ToggleButtonGroup,ButtonGroup } from 'react-bootstrap';
import InputGroup from 'react-bootstrap/InputGroup';

const ViewEquipmentLog = props => {
    console.log(props)

    const equipmentId = props.equipmentId;
    const equipmentType = props.equipmentType;
    const userId = props.user.user_id;
    const userName= props.user.user_name;

    const [log,setLog] = useState([]);
    const [logType,setLogType] = useState(0)
    const [show2, setShow2] = useState(false);
    const [show3, setShow3] = useState(false);
    const [show4, setShow4] = useState(false);
    const [noteLoading,setNoteLoading] = useState(false);
    const [description,setDescription] = useState(false);
    const [equipmentLogList, setEquipmentLogList] = useState([]);
    const [ticket,setTicket] = useState("");
    const [isLoaded,setIsLoaded] = useState(false);
    const [error,setError] = useState(null);
    const [logState,setLogState] = useState([]);
    const [noteState,setNoteState] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    const [logID, setLogID] = useState(null);
    const [noteID,setNoteID] = useState(null);
    const [optionID,setOptionID] = useState(null);
    const [showNotes,setShowNotes] = useState(false);
    const [equipmentNoteList,setEquipmentNoteList] = useState([]);
    const [viewNote,setViewNote] = useState(false);
    const [viewNoteImage,setViewNoteImage] = useState(null);
    const [viewNoteText,setViewNoteText] = useState(null);
    const [saveLogLoading,setSaveLogLoading] = useState(false);
    const [showAreUsureDelete,setShowAreUsureDelete] = useState(false);
    const [metaLoaded,setMetaLoaded] = useState(false);
    const [showEquipmentLogs,setShowEquipmentLogs] = useState(false);
    const [logAlert,setLogAlert] = useState(null)




    const [equipmentMeta, setEquipmentMeta] = useState([]);

    const handleClose2 = () => setShow2(false);
    const handleClose3 = () => setShow3(false);
    const handleClose4 = () => setShow4(false);
    const handleCloseNotes = () => setShowNotes(false);
    const handleCloseNote = () => setViewNote(false);
    const handleShow2 = () => {
        setShow2(true);
        setShowEquipmentLogs(false)
    }
    const handleShow3 =  (key) => {  

        setLogType(key)/// Add Log ////
        setShow2(false);
        setShow3(true);
        addLog(key);
    }



    const handleShow4 = () => {
        setShow4(true);
        setShowNotes(false);
    }
    const handleBack2 = () => {
        setShow2(false);
        setShowEquipmentLogs(true)
    }

    const handleBack3 = () => {
        handleOpenEquipmentLogs();
        setShow3(false);
    }
    const handleBack4 = () => {
        setShow4(false);
        setShowNotes(true);
    }

    const handleBackNotes = () => {
        setShowNotes(false);
        setShow3(true);
    }

    const handleBackNote = () => {
        setViewNote(false);
        setShowNotes(true);
    }

    const handleShowNotes = (option) => {
        setOptionID(option);
        console.log(option);
        setShowNotes(true);
        setShow3(false);
    }

    const handleCloseAreUsureDelete = () => {
        setShowAreUsureDelete(false);
    }

    useEffect(()=>{
        loadEquipmentMeta();
    },[])

    useEffect(()=>{
        if(showEquipmentLogs == true){
            viewEquipmentLogs(equipmentId);
        }
    },[showEquipmentLogs])

    useEffect(()=>{
        if(showNotes == true){
            viewEquipmentNotes();
        }
    },[showNotes])

    const viewEquipmentNotes = async () => {
        console.log(optionID)
        const data = await fetch("https://elevationrockgym.com/api/view_notes.php",{
            method: 'POST',
            body: JSON.stringify({
                option_id: optionID,
                log_id: logID
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setEquipmentNoteList(result)
        },
        (error) => {
            setError(error)
        }
        )
    }

    const viewEquipmentLogs = async (equipmentId) => {
        const data = await fetch("https://elevationrockgym.com/api/view_equipment_logs.php",{
            method: 'POST',
            body: JSON.stringify({
                equipment_id: equipmentId
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setEquipmentLogList(result)
            setIsLoaded(true)
        },
        (error) => {
            setIsLoaded(true)
            setError(error)
        }
        )
    }

    const noteDetails = async (id,text,image) => {
        await setNoteID(id)
        await setViewNoteImage(image);
        await setViewNoteText(text);
        setViewNote(true);
        setShowNotes(false);
    }
    const logDetails = async (logId,logValues,logAl,logT) =>{
        console.log('details ran')
        setShow3(true);
        handleCloseEquipmentLogs();
        await setLogAlert(logAl)
        await setLogID(logId);
        await setLogState(logValues);
        await setLogType(logT);
    }

    const handleBack = () => {
        setShow2(false);
    }

    const deleteFixIt = async (ticketID) =>{
        await fetch("https://elevationrockgym.com/api/delete_fix_it.php",{
            method: 'POST',
            body: JSON.stringify({
                ticket_id: ticketID
            })
        });
        console.log(ticketID);
       handleBack();
    }

    const addLog = async (key) => {

        const data = await fetch("https://elevationrockgym.com/api/add_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                log_length: equipmentMeta[key]['Layout'].length,
                equipment_id: equipmentId,
                equipment_type: equipmentType,
                log_type: equipmentMeta[key]['id']
            })
        })
        .then(result => result.json())
        .then(
        (result) => {
            console.log(result)
            setLogState(result[1]);
            setLogID(result[0]);
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const saveLog = async () => {
        setSaveLogLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/save_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                alert: logAlert,
                log_state: logState,
                log_id: logID,
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setSaveLogLoading(false);
            handleBack3()
            props.reload();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const signLog = async () => {
        setSaveLogLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/sign_equipment_log.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userId,
                user_name: userName,
                log_state: logState,
                log_id: logID,
                alert: logAlert
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setSaveLogLoading(false);
            handleBack3();
            props.reload()
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )

    }

    const addNote = async () => {
        setNoteLoading(true);
        const formData = new FormData();
        formData.append('my_image',selectedImage)
        formData.append('note_text',noteState);
        formData.append('log_id',logID);
        formData.append('option_id',optionID)
        console.log(formData)
        const data = await fetch("https://elevationrockgym.com/api/add_note.php",{
            method: 'POST',
            headers: {
                'Accept': 'application/json',
            },
            body: formData
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setNoteLoading(false);
            handleBack4();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )
    }
    const loadEquipmentMeta = async () => {
        const data = await fetch("https://elevationrockgym.com/api/equipment.json",{
            method: 'POST'
        })
        .then(result => result.json())
        .then(
            (result) =>{
                setEquipmentMeta(result[equipmentType]);
                setMetaLoaded(true);
            }
        )
    }

    const changeOption = (e,optionId) => {
        
        console.log(e);
        console.log(optionId);

        // Load Log State //
        const logTemp = logState;
        logTemp[optionId] = e;
        setLogState(logTemp);
    }

    const handleNoteText = event => {
        setNoteState(event.target.value)
    }

    const deleteNote = async () => {
        setShowAreUsureDelete(false)
        setNoteLoading(true)
        const data = await fetch("https://elevationrockgym.com/api/delete_note.php",{
            method: 'POST',
            body: JSON.stringify({
                note_id:noteID
            })
        })
        .then(result => result.text())
        .then(
        (result) => {
            console.log(result)
            setNoteLoading(false);
            handleBackNote();
        },
        (error) => {
            setError(error)
            console.log(error)
        }
        )
    }
    const handleOpenEquipmentLogs = () => {
        setShowEquipmentLogs(true);
    }

    const handleCloseEquipmentLogs = () => {
        setShowEquipmentLogs(false)
    }
    
    const colorArray = ['','warning','danger'];
    
    if(!isLoaded){
        return(
            <div>
                <Button onClick={()=>handleOpenEquipmentLogs()}>View/Add Logs</Button>                                                               
            </div>
        )
    }  else if(isLoaded && metaLoaded){
        return(
            <div>
                
                <Button onClick={()=>handleOpenEquipmentLogs()}>View/Add Logs</Button>
                <Modal show={showEquipmentLogs} onHide={handleCloseEquipmentLogs}>
                    <Modal.Header closeButton>
                        <Modal.Title>Equipment Logs</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Button onClick={handleShow2} >
                            Add Log
                        </Button>
                        <ListGroup>
                            {console.log(equipmentLogList)}
                            {equipmentLogList.map((item =>(
                            
                                <ListGroup.Item variant={colorArray[item.alert]} action onClick={()=>logDetails(item.log_id,item.log_values,item.alert,item.log_type)}>
                                    {item.last_save} {item.user_name} {equipmentMeta[item.log_type]['Name']}
                                </ListGroup.Item>
    
                            )))}
                        </ListGroup>
                    
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseEquipmentLogs}>Close</Button>
                    </Modal.Footer>
                </Modal>
    
                <Modal show={showAreUsureDelete} onHide={handleCloseAreUsureDelete}>
                    <Modal.Header closeButton>
                        <Modal.Title>Are you sure?</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        Are you sure you want to delete this note?
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseAreUsureDelete}>No</Button>
                        <Button variant="primary" onClick={() => deleteNote()}>Yes</Button>
                    </Modal.Footer>
                </Modal>
                
                

                <Modal show={show2} onHide={handleClose2}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {console.log(equipmentMeta[logType])}
                        {equipmentMeta.map((item,i) => {
                            return <div><Button onClick={()=>handleShow3(i)}>{item['Name']}</Button></div>
                        }

                        )}
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleBack2}>Back</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={show3} onHide={handleClose3}>
                    <Modal.Header closeButton>
                        <Modal.Title>Save Log</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        {console.log(logState)}
                        {console.log(equipmentMeta[logType])}
                       
                        {equipmentMeta[logType]['Layout'].map((item => (
                            
                            <ButtonGroup>
                                <Button style={{ width: "250px"}} variant="outline-secondary" disabled >{item['text']} [?]</Button>


                                {console.log("item:  " + item['id'])}
                                {console.log("state:  " + logState[item['id']])}
                                    <ToggleButtonGroup onChange={(e) => changeOption(e,item['id'])} type="radio" name="options" defaultValue={logState[item['id']]}>
                                        <ToggleButton className="Btn-G"  value={"0"}>G</ToggleButton>
                                        <ToggleButton className="Btn-TM" value={"1"}>TM</ToggleButton>
                                        <ToggleButton className="Btn-R"  value={"2"}>R</ToggleButton>
                                    </ToggleButtonGroup>
                                
                                <Button variant="outline-secondary" onClick={()=>handleShowNotes(item['id'])}>Notes</Button>

                            
                                
                            </ButtonGroup>

                            
                        )))}




                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" disabled={saveLogLoading} onClick={handleBack3}>Back</Button>
                        <Button variant="primary" disabled={saveLogLoading} onClick={()=>saveLog()}>Save</Button>
                        <Button variant="success" disabled={saveLogLoading} onClick={()=>signLog()}>Sign and Submit</Button>
                    </Modal.Footer>
                </Modal>   
                <Modal show={show4} onHide={handleClose4}>
                    <Modal.Header closeButton>
                        <Modal.Title>Add Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <InputGroup>
                            <InputGroup.Text>Notes</InputGroup.Text>
                            <Form.Control as="textarea" aria-label="Add Notes" onChange={handleNoteText} />
                        </InputGroup>
                        <Form.Group controlId="formFile" className="mb-3">
                            <Form.Label>Choose Image</Form.Label>
                            <Form.Control type="file"         
                            name="myImage"
                            onChange={(event) => {
                                setSelectedImage(event.target.files[0]);
                                
                                }} 
                            />
                        </Form.Group>

                        {selectedImage && (
                            <div>
                            <img alt="not found" width={"250px"} src={URL.createObjectURL(selectedImage)} />
                            <br />
                            <button onClick={()=>setSelectedImage(null)}>Remove</button>
                            </div>
                        )}
                        
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" disabled={noteLoading} onClick={handleBack4}>Back</Button>
                        <Button variant="primary" disabled={noteLoading} onClick={()=>addNote()}>Add Note</Button>
                    </Modal.Footer>
                </Modal>
                <Modal show={showNotes} onHide={handleCloseNotes}>
                    <Modal.Header closeButton>
                        <Modal.Title>Notes</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <ListGroup>
                            {equipmentNoteList.map((item =>(
                                <ListGroup.Item action onClick={()=>noteDetails(item.note_id,item.note_text,item.image_url)}>
                                    {item.note_text}
                                </ListGroup.Item>
                            )))}
                        </ListGroup>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleBackNotes}>Back</Button>
                        <Button variant="primary" onClick={handleShow4} >
                            Add Note
                        </Button>
                    </Modal.Footer>
                </Modal>  
                <Modal show={viewNote} onHide={handleCloseNote}>
                    <Modal.Header closeButton>
                        <Modal.Title>Note Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                                <div>
                                    {viewNoteText}
                                </div>
                                <div>
                                    <img alt="not found" width={"250px"} src={"https://elevationrockgym.com/api/uploads/" + viewNoteImage} />
                                </div>
                    </Modal.Body>

                    <Modal.Footer>
                        <Button variant="secondary" disabled={noteLoading} onClick={handleBackNote}>Back</Button>
                        <Button variant="secondary" disabled={noteLoading} onClick={()=>setShowAreUsureDelete(true)}>Delete</Button>
                    </Modal.Footer>
                </Modal>      
            </div>
        )
    } else {
        return(
            <div>
                <Spinner animation="border" role="status" /> 
            </div>
        )
    } 
}


export default ViewEquipmentLog;