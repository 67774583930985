import { set } from "lodash-es";
import React, {useState, useEffect} from "react";
import { Spinner, Table, Accordion, Card, Button } from 'react-bootstrap';


import './leaderboard.css';

const Leaderboard = props => {

    const user = props.user;
    const [leaderboard,setLeaderboard] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [fetchUsers, setFetchUsers] = useState([]);
    const [fetchUsersIsLoaded,setFetchUsersIsLoaded] = useState(false);
    const [fetchTeams, setFetchTeams] = useState([]);
    const [fetchTeamsIsLoaded,setFetchTeamsIsLoaded] = useState(false);

    const fetchLeaderboard = async () => {
        const data = await fetch("https://elevationrockgym.com/api/boulder_club_leaderboard.php",{
            method:'POST'
        })
        .then(result => result.json())
        .then(
            (result) => {
                setLeaderboard(result);
                setIsLoaded(true);
            }
        )
    } 
    
    const fetchUserInfo = async () => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_user_info.php")
        .then(result => result.json())
        .then(
            (result) => {
                setFetchUsers(result);
                setFetchUsersIsLoaded(true);
            }
        )
    }
    const fetchTeamInfo = async () => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_team_info.php")
        .then(result => result.json())
        .then(
            (result) => {
                setFetchTeams(result);
                setFetchTeamsIsLoaded(true);
            }
        )
    }

    useEffect(()=>{
        setIsLoaded(false);
        setFetchTeamsIsLoaded(false);
        setFetchUsersIsLoaded(false);   
        fetchUserInfo()
        fetchTeamInfo()
        fetchLeaderboard();
    },[user])


    if(isLoaded && fetchUsersIsLoaded && fetchTeamsIsLoaded){
        const userInfo = fetchUsers;
        const teamInfo = fetchTeams;
        console.log(teamInfo)
        const indLeaderboard = leaderboard.individual;
        const teamLeaderboard = leaderboard.team;
        console.log(teamLeaderboard)

        return(
            <div>
                <Accordion>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="0">
                                Individual Leaderboard
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="0">
                            <Card.Body>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Score
                                        </th>
                                    </thead>
                                    <tbody>
                                    {indLeaderboard.map((item => (
                                        
                                    
                                        <tr>
                                            <td>
                                                <h1>
                                                    {userInfo[item.user_id].user_name}
                                                </h1>
                                            </td>
                                            <td>
                                                <p>
                                                {item[`SUM(total_score)`]} 
                                                </p>
                                            </td>
                                        </tr>
                                    )))} 
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                    <Card>
                        <Card.Header>
                            <Accordion.Toggle as={Button} variant="link"  eventKey="1">
                                Team Leaderboard
                            </Accordion.Toggle>
                        </Card.Header>
                        <Accordion.Collapse eventKey="1">
                            <Card.Body>
                                <Table striped bordered hover size="sm">
                                    <thead>
                                        <th>
                                            Name
                                        </th>
                                        <th>
                                            Score
                                        </th>
                                    </thead>
                                    <tbody>
                                    {teamLeaderboard.map((item => (
                                        
                                    
                                        <tr>
                                            <td>
                                                <h1>
                                                {teamInfo[item.team_id].team}
                                                </h1>
                                            </td>
                                            <td>
                                                <p>
                                                {item[`SUM(total_score)`]} 
                                                </p>
                                            </td>
                                        </tr>
                                    )))} 
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Accordion.Collapse>
                    </Card>
                </Accordion>  
            </div>
        )
    } else {
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default Leaderboard;
