import React, {useState, useEffect} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import { Spinner} from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Gym from '../Equipment/Gym';
import Todo from '../Equipment/Todo';
import WarningCard from '../Equipment/warningCard';

const Equipment = () => {

    const { user, isAuthenticated } = useAuth0();
    const [userProfile, setUserProfile] = useState([]);
    const [isLoaded, setIsLoaded ] = useState(false);
    const [isPermit,setIsPermit]  = useState(false);
    const [reloadCounter,setReloadCounter] = useState(0)


    useEffect(()=>{
        console.log("hel")
        if(isAuthenticated){
            checkStatus(user.sub,user.email,user.nickname)
        }
    },[isAuthenticated]);

    useEffect(()=>{
        if(isLoaded){
            if(userProfile.permissions == 1){
                setIsPermit(true)
            }
        }
    },[isLoaded])

    const checkStatus = async (userID, userEmail, userNick) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userID,
                user_email: userEmail,
                user_nick: userNick   
            })
        })
        .then(result => result.json())
        .then((result) => {
            console.log(result)
            setUserProfile(result)
            setIsLoaded(true)
        }
        );
    }
    const pushReload = async () =>{
        console.log('reload')
        setReloadCounter(reloadCounter+1)
        
    }
    
    if(!isLoaded){
        return(
            <div>
                <Spinner animation="border" role="status" />    
            </div>
        )
    } else if((reloadCounter>=0) && isAuthenticated && isLoaded && userProfile.permissions==2) {
        return(
            <div>
                
                <Todo user={userProfile} refresh={reloadCounter} reload={pushReload} />
                <WarningCard refresh={reloadCounter}/>

                <Tabs defaultActiveKey="gym" id="browseList" >
                    <Tab eventKey="gym" title="Gym">
                        <Gym user={userProfile} refresh={reloadCounter} reload={pushReload} />
                    </Tab>
                    <Tab eventKey="rental" title="Rental">
                        
                    </Tab>
                    <Tab eventKey="setting" title="Setting">
                        
                    </Tab>
                </Tabs>
            </div>
        )
    } else if(isAuthenticated && isLoaded && userProfile.permissions==1) {
        return(
            <div>
                
                <Todo user={userProfile} refresh={reloadCounter} reload={pushReload}/>
            </div>
        )
    } else if(isLoaded && userProfile.permissions == 0) {
        return(
            <div>
                Not permitted
            </div>
        )
    } else if(isLoaded && userProfile.permissions == null) {
        return(
            <div>
                Not permitted
            </div>
        )
    }

}

export default Equipment;