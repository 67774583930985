import React, {useState, useEffect} from 'react';
import { Spinner, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import './listEquipment.css';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import Badge  from 'react-bootstrap/Badge';
import ViewTodoEquipmentLogs from './viewTodoEquipmentLogs';
import { set } from 'lodash-es';

const Todo = props => {

    const userId = props.user.user_id;
    const userName= props.user.user_name;

    const [equipmentTodo,setEquipmentTodo] = useState([]);
    const [equipType,setEquipType] = useState(null);
    const [isLoaded,setIsLoaded] = useState(false);
    const [showLogs,setShowLogs] = useState(false);
    const [equipInfo, setEquipInfo] = useState(false);
    const [isClicked,setIsClicked] = useState(false);
    const [tabDefault, setTabDefault] = useState("Daily");
    const [accDefault,setAccDefault] = useState('0')

    
    console.log(props)

    const handleCloseLogs = () =>{
        setIsClicked(false)

    }

    const handleShowLogs = (info,acc,tab) => {
        console.log(acc)
        setTabDefault(tab)
        setAccDefault(tab+acc)
        setEquipInfo(info)
        setIsClicked(true);
    }
    
    
    useEffect(()=>{
        queryTodo();
    
    },[])

    useEffect(()=>{
        queryTodo();
        
    },[props.refresh])


    const queryTodo = async () => {
        console.log('haa')
        const data = await fetch("https://elevationrockgym.com/api/view_todo_equipment.php",{
            method: 'POST'
        })
        .then(result => result.json())
        .then((result)=>{
            setEquipmentTodo(result);
            setIsLoaded(true);
        });
        //props.reload()
    }
    





    if(!isLoaded){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    } else if(isLoaded && !isClicked){
        return(

            <div>
                {console.log(equipmentTodo)}   
                <Tabs defaultActiveKey={tabDefault}  id="todoList" >
                    {Object.keys(equipmentTodo).map((frequency)=>(
                        <Tab eventKey={frequency} title={frequency}>

                            <Accordion defaultActiveKey={accDefault.toString()}>

                                {Object.keys(equipmentTodo[frequency]).map((equipmentType,index)=>(
                                    <Card>
                                        <Card.Header>
                                            <div>
                                            <Accordion.Toggle as={Button} variant="link"  eventKey={(frequency+index).toString()}>
                                                {equipmentType}
                                                
                                            </Accordion.Toggle>
                                            <Badge pill bg="warning" text="dark">
                                                <font color="red">
                                                    {equipmentTodo[frequency][equipmentType].length}
                                                </font>
                                            </Badge>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={(frequency+index).toString()}>
                                            <Card.Body>
                                                <ListGroup>
                                                    
                                                    {equipmentTodo[frequency][equipmentType].map((todoLog)=>(
                                                        <ListGroup.Item action onClick={()=>handleShowLogs(todoLog,index,frequency)}>
                                                            
                                                            <table className='equip_item'>
                                                                <tr>
                                                                    <td>
                                                                        {todoLog['identifier']}
                                                                    </td>
                
                                                                </tr>
                                                            </table>
                                                        </ListGroup.Item>
                                                    ))}

                                                </ListGroup>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))}

                            </Accordion>
                        </Tab>
                    ))}

                </Tabs>

            </div>
        )

    } else if(isLoaded && isClicked){
        return(
            <div>

                
                <ViewTodoEquipmentLogs close={handleCloseLogs} user={props.user} equipInfo={equipInfo} reload={props.reload} refresh={queryTodo}/>
                         
                <Tabs defaultActiveKey={tabDefault} id="todoList" >
                    {Object.keys(equipmentTodo).map((frequency)=>(
                        <Tab eventKey={frequency} title={frequency}>

                            <Accordion defaultActiveKey={accDefault.toString()}>

                                {Object.keys(equipmentTodo[frequency]).map((equipmentType,index)=>(
                                    <Card>
                                        <Card.Header>
                                            <div>
                                            <Accordion.Toggle as={Button} variant="link"  eventKey={(frequency+index).toString()}>
                                                {equipmentType}
                                                
                                            </Accordion.Toggle>
                                            <Badge pill bg="warning" text="dark">
                                                <font color="red">
                                                    {equipmentTodo[frequency][equipmentType].length}
                                                </font>
                                            </Badge>
                                            </div>
                                        </Card.Header>
                                        <Accordion.Collapse eventKey={(frequency+index).toString()}>
                                            <Card.Body>
                                                <ListGroup>

                                                    {equipmentTodo[frequency][equipmentType].map((todoLog)=>(
                                                        <ListGroup.Item action onClick={()=>handleShowLogs(todoLog,index,frequency)}>
                                                            <table className='equip_item'>
                                                                <tr>
                                                                    <td>
                                                                        {todoLog['identifier']}
                                                                    </td>
                
                                                                </tr>
                                                            </table>
                                                        </ListGroup.Item>
                                                    ))}

                                                </ListGroup>

                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>


                                ))}

                            </Accordion>
                        </Tab>
                    ))}

                </Tabs>

            </div>
        )
    }
}

export default Todo;