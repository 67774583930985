import React, {useState, useEffect} from "react";
import ListTeams from "./listTeam";
import CreateTeam from "./createTeam";
import {Spinner} from "react-bootstrap";
import FetchTeamRequests from "./fetchTeamRequests";

const JoinTeam = props => {
    const user = props.user;

    const [onTeam,setOnTeam] = useState([]);
    const [isChecked, setIsChecked] = useState(false)
    
    const checkTeam = async (team_id) => {
        setIsChecked(false);
        const data = await fetch("https://elevationrockgym.com/api/query_team.php",{
            method: 'POST',
            body: JSON.stringify({
                team_id: team_id    
            })
        })
        .then(result => result.json())
        .then((result)=>{
            setOnTeam(result);
            setIsChecked(true);
        });
    }

    const quitTeam = async (userID,teamID) => {

        const data = await fetch("https://elevationrockgym.com/api/quit_team.php",{
            method: 'POST',
            body: JSON.stringify({
                user_id: userID,
                team_id: teamID    
            })
        })
        .then(result => {
            props.updateUser(user.user_id)
            setOnTeam("");
            console.log(result.text())
        }
        );
        
    }
    useEffect(()=>{
        checkTeam(user.team_id)
    },[user])

    if(onTeam && isChecked ){
        return(
            <div>
                You are on team {onTeam.team}
                <button onClick={()=>quitTeam(user.user_id,user.team_id)} >Quit Team</button>
                <FetchTeamRequests team={onTeam}/>

                
            </div>
        )
    } else if(!onTeam && isChecked) {
        return(
            <div>   
                <CreateTeam user={user} updateUser={props.updateUser} />    

                <ListTeams user={user} />
            </div>
        )
    } else if(!isChecked){
        return(
            <div>
                <Spinner animation="border" role="status" />
            </div>
        )
    }
}

export default JoinTeam;