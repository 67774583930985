import React, {useState, useEffect} from "react";
import UnSetRoute from "../Forms/unSetRoute";
import FixIt from "../Forms/fixIt";
import EditRoute from "../Forms/editRoute";
import ViewFixIt from "../Forms/viewFixIt";

const QRAdmin = props => {

    const [userProfile, setUserProfile] = useState([]);
    const [isLoaded, setIsLoaded ] = useState([]);
    const user = props.user;
    const routeID = props.route.id;
    const route = props.route;
    console.log(route)
    

    useEffect(()=>{
        checkStatus(user.sub,user.email,user.nickname)
    },[]);

    const checkStatus = async (userID, userEmail, userNick) => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_profile.php",{
      method: 'POST',
      body: JSON.stringify({
        user_id: userID,
        user_email: userEmail,
        user_nick: userNick   
      })
    })
    .then(result => result.json())
    .then((result) => {
        console.log(result)
        setUserProfile(result)
        setIsLoaded(true)
      }
    );
    }
    if(isLoaded && userProfile.permissions >= 1){
        return (
            <div>
                <table>
                    <td>
                        <FixIt user = {user} routeID = {routeID} />
                    </td>
                    <td>
                        <ViewFixIt user = {user} routeID = {routeID} />    
                    </td>
                    <td>
                        <EditRoute user = {user} route = {route} />
                    </td>                    
                    <td>
                        <UnSetRoute user = {user} routeID = {routeID} />
                    </td>
                </table>
            </div>
        )
    } else if(isLoaded && userProfile.permissions ==0){
        return (
            <div>
                
            </div>
        )

    } else {
        return (
            <div>
                Authenticating...
            </div>
        )
    }


}

export default QRAdmin;