import React, { Component } from 'react'
import {BrowserRouter as Router, Switch, Route} from 'react-router-dom';


import Toolbar from './components/Toolbar/Toolbar'
import SideDrawer from './components/SideDrawer/SideDrawer'
import Backdrop from './components/Backdrop/Backdrop'
import Home from './components/Screens/Home';
import RouteDetail from './components/Screens/RouteDetail';
import Map from './components/Screens/Map';

import UpAllNight from './components/Screens/UpAllNight';
import Browse from './components/Screens/Browse';
import Profile from './components/Screens/Profile';
import BoulderClub from './components/Screens/BoulderClub';
import Equipment from './components/Screens/Equipment';
import Competitions from './components/Screens/Competitions';
import Everest from './components/Screens/Everest';


class App extends Component {
  state = {
    sideDrawerOpen: false,
  }

  drawerToggleClickHandler = () => {
    this.setState(prevState => {
      return { sideDrawerOpen: !prevState.sideDrawerOpen }
    })
  }

  backdropClickHandler = () => {
    this.setState({ sideDrawerOpen: false })
  }

  render() {
    let backdrop;


    if (this.state.sideDrawerOpen) {
      backdrop = <Backdrop click={this.backdropClickHandler} />
    }
    return (
        <div style={{ height: '100%' }}>
          <Toolbar drawerClickHandler={this.drawerToggleClickHandler} />
          <SideDrawer show={this.state.sideDrawerOpen} />
          {backdrop}
          <main style={{ marginTop: '64px' }}>
            <Switch>
              <Route path="/" exact component={Home} />
              <Route path="/home" exact component={Home} />
              <Route path={"/qr/:id"} exact component={RouteDetail} />
              <Route path="/map" exact component={Map} />
              <Route path="/upallnight" exact component={UpAllNight} />
              <Route path="/browse" exact component={Browse} />
              <Route path="/profile" exact component={Profile} />
              <Route path="/competitions" exact component={Competitions} />
              <Route path="/equipment" exact component={Equipment} />
              <Route path="/boulderclub" exact component={BoulderClub} />
              <Route path="/everest" exact component={Everest} />
            </Switch>
          </main>
        </div>
    )
  }
}

export default App