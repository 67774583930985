import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import Toast from 'react-bootstrap/Toast'

const AddRoute = props => {


    const user = props.user;

    const qrID = props.qrID;
    const current = new Date();
    const today = `${current.getFullYear()}-${current.getMonth()+1}-${current.getDate()}`;


    const [routeName,setRouteName] = useState("");
    const [setter,setSetter] = useState(user.user_name);
    const [color,setColor] = useState("");
    const [date,setDate] = useState("");
    const [type,setType] = useState("");
    const [location,setLocation] = useState("");
    const [week,setWeek] = useState("0");
    const [points,setPoints] = useState("0");
    const [showA, setShowA] = useState(false);
  
    const toggleShowA = () => setShowA(!showA);



    
    const add = async () => {
        const data = await fetch("https://elevationrockgym.com/api/add_route.php",{
            method: 'POST',
            body: JSON.stringify({
                qr_id: qrID,
                user_id: user.user_id,
                route_name: routeName,
                color: color,
                date: date,
                type: type,
                location: location,
                setter: setter,
                week: week,
                points: points
            })
        })
        .then(result => result.text())
        .then((result)=>{
        });

        window.location.reload(false);
       
    }

    const handleRouteNameChange = event => {
        setRouteName(event.target.value)
        console.log(routeName)
    }


    const handleColorChange = event => {
        setColor(event.target.value);
    }

    const handleSetterChange = event => {
        setSetter(event.target.value);
    }

    const handleDateChange = event => {
        setDate(event.target.value);
    }

    const handleLocationChange = event => {
        setLocation(event.target.value);
    }

    const handleTypeChange = event => {
        setType(event.target.value);
    }
    const handlePointsChange = event => {
        setPoints(event.target.value)
    }

    const handleWeekChange = event => {
        setWeek(event.target.value)
    }

    useEffect(()=>{
        if((type === "B") || (type === "DB")){
            setShowA(true);
        } else {
            setWeek("0");
            setShowA(false);
        }
    },[type])

    return(
        <div>
            <Form> 
            <Form.Group >
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Route Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" onChange={handleRouteNameChange} value={routeName} />
                    </InputGroup>  
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleColorChange}>
                            <option></option>
                            <option>Pink</option>
                            <option>Red</option>
                            <option>Orange</option>
                            <option>Blue</option>
                            <option>Green</option>
                            <option>Black</option>
                            <option>White</option>
                            <option>Tan</option>
                            <option>Yellow</option>
                            <option>Grey</option>
                            <option>Neon Green</option>
                            <option>Purple</option>
                            <option>Seafoam</option>
                            <option>Other</option>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Setter</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleSetterChange}>
                            <option>{user.user_name}</option>
                            <option>Brian</option>
                            <option>Anderson</option>
                            <option>Monica</option>
                            <option>Denzil</option>
                            <option>Cody</option>
                            <option>Stef</option>
                            <option>Seersha</option>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Date</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" onChange={handleDateChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Location</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="number"  min="1" max="40" onChange={handleLocationChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Type</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleTypeChange}>
                            <option></option>
                            <option value="R">Ropes</option>
                            <option value="B">Upstairs Bouldering</option>
                            <option value="DB">Downstairs Bouldering</option>
                        </Form.Control>
                    </InputGroup>
                    <Toast show={showA} onClose={toggleShowA}>
                        <Toast.Header>
                            <strong className="mr-auto">Boulder Club Information</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Boulder Club Week</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" onChange={handleWeekChange}>
                                <option value="0">Not in Boulder Club</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </Form.Control>
                            </InputGroup>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Points</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" onChange={handlePointsChange} value={points} />
                            </InputGroup>
                        </Toast.Body>
                    </Toast>    
                </Form.Group>
                <Button variant="primary" onClick={add}>
                    Submit
                </Button>
            </Form>
        </div>
    )
}


export default AddRoute;