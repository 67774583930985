import React, { Component } from 'react';
import Select from 'react-select';
import RouteMap from '../Map/RouteMap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';

import 'bootstrap/dist/css/bootstrap.min.css';
import './map.css';

class Map extends Component {
    constructor(props){
        super(props);
        this.state = {cgrade: [],cgradeUB: [],cgradeDB: [],tgrade: [],tgradeUB: [],tgradeDB: [],type: false};
    
        this.multiChange = this.multiChange.bind(this);
        this.multiUBChange = this.multiUBChange.bind(this);
        this.multiDBChange = this.multiDBChange.bind(this);


    }
    //  Upstairs Bouldering //

    async multiUBChange(value){
        await this.setState({tgradeUB: value})
        const searchGradeUB = [];

        if(this.state.tgradeUB){
            this.state.tgradeUB.map((item)=>(
                searchGradeUB.push(item.value)

            ))
        }
        await this.setState({cgradeUB: searchGradeUB})
    }

    //  Downstairs Bouldering //


    async multiDBChange(value){
        await this.setState({tgradeDB: value})
        const searchGradeDB = [];

        if(this.state.tgradeDB){
            this.state.tgradeDB.map((item)=>(
                searchGradeDB.push(item.value)

            ))
        }
        await this.setState({cgradeDB: searchGradeDB})
    }

    //  Ropes //

    async multiChange(value){
        await this.setState({tgrade: value})
        const searchGrade = [];

        if(this.state.tgrade){
            this.state.tgrade.map((item)=>(
                searchGrade.push(item.value)

            ))
        }
        await this.setState({cgrade: searchGrade})
    }

    render() {
        return (
            <div>
            <Tabs defaultActiveKey="boulders" id="test" >
                <Tab eventKey="boulders" title="Upstairs Bouldering">
                    <div>
                        <div className="gradeForm">
                            <Select
                                closeMenuOnSelect={false}
                                defaultValue={'V1'}
                                isMulti
                                options= {[
                                    {value: 'V0', label: 'V0'},
                                    {value: 'V1', label: 'V1'},
                                    {value: 'V2', label: 'V2'},
                                    {value: 'V3', label: 'V3'},
                                    {value: 'V4', label: 'V4'},
                                    {value: 'V5', label: 'V5'},
                                    {value: 'V6', label: 'V6'},
                                    {value: 'V7', label: 'V7'},
                                    {value: 'V8', label: 'V8'},
                                    {value: 'V9', label: 'V9'},
                                    {value: 'V10', label: 'V10'},
                                    {value: 'V11', label: 'V11'},
                                    {value: 'V12', label: 'V12'},
                                    {value: 'V13', label: 'V13'},
                                    {value: 'V14', label: 'V14'},
                                    {value: 'V15', label: 'V15'},
                                ]}
                                value={this.state.tgradeUB}
                                onChange={this.multiUBChange}
                            />
                        </div>
                
                        <div className="routeMap">
                            <RouteMap searchOptions={{cgrade:this.state.cgradeUB}} type='B' />
                        </div>

                    </div>
                </Tab>
                <Tab eventKey="dboulders" title="Downstairs Bouldering">
                    <div>
                        <div className="gradeForm">
                            <Select
                                closeMenuOnSelect={false}
                                defaultValue={'V1'}
                                isMulti
                                options= {[
                                    {value: 'V0', label: 'V0'},
                                    {value: 'V1', label: 'V1'},
                                    {value: 'V2', label: 'V2'},
                                    {value: 'V3', label: 'V3'},
                                    {value: 'V4', label: 'V4'},
                                    {value: 'V5', label: 'V5'},
                                    {value: 'V6', label: 'V6'},
                                    {value: 'V7', label: 'V7'},
                                    {value: 'V8', label: 'V8'},
                                    {value: 'V9', label: 'V9'},
                                    {value: 'V10', label: 'V10'},
                                    {value: 'V11', label: 'V11'},
                                    {value: 'V12', label: 'V12'},
                                    {value: 'V13', label: 'V13'},
                                    {value: 'V14', label: 'V14'},
                                    {value: 'V15', label: 'V15'},
                                ]}
                                value={this.state.tgradeDB}
                                onChange={this.multiDBChange}
                            />
                        </div>
                
                        <div className="routeMap">
                            <RouteMap searchOptions={{cgrade:this.state.cgradeDB}} type='DB' />
                        </div>

                    </div>
                </Tab>
                <Tab eventKey="ropes" title="Ropes">
                    <div>
                        <div className="gradeForm">
                            <Select
                                closeMenuOnSelect={false}
                                defaultValue={'5.10'}
                                isMulti
                                options= {[
                                    {value: '5.6', label: '5.6'},
                                    {value: '5.7', label: '5.7'},
                                    {value: '5.8', label: '5.8'},
                                    {value: '5.9', label: '5.9'},
                                    {value: '5.9+', label: '5.9+'},
                                    {value: '5.10-', label: '5.10-'},
                                    {value: '5.10', label: '5.10'},
                                    {value: '5.10+', label: '5.10+'},
                                    {value: '5.11-', label: '5.11-'},
                                    {value: '5.11', label: '5.11'},
                                    {value: '5.11+', label: '5.11+'},
                                    {value: '5.12-', label: '5.12-'},
                                    {value: '5.12', label: '5.12'},
                                    {value: '5.12+', label: '5.12+'},
                                    {value: '5.13-', label: '5.13-'},
                                    {value: '5.13', label: '5.13'},
                                ]}
                                value={this.state.tgrade}
                                onChange={this.multiChange}
                            />
                        </div>
                        
                        <div className="routeMap">
                            <RouteMap searchOptions={{cgrade:this.state.cgrade}} type='R' />
                        </div>
                    </div>
                </Tab>
            </Tabs>
            </div>
        )
    }
}

export default Map;
