import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { Button, Input, ButtonGroup, Form, Spinner } from 'react-bootstrap';
import  InputGroup  from "react-bootstrap/InputGroup";
import './routes.css';
import { FormControl } from "react-bootstrap";
import Ticks from "./Ticks";

const Scorecard = props => {

    const type = props.type;
    const userProfile = props.user;
    const userID = userProfile.user_id;

    const [routeList,setRouteList] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [isUserLoaded,setIsUserLoaded] = useState(false);
    const [userTicks,setUserTicks] = useState([]);
    const [error,setError] = useState(null)
    const [pointMeta,setPointMeta] = useState([]);

    useEffect(()=>{
        fetchRoutes();
    },[]);

    useEffect(()=>{
        initialQuery()
        console.log(routeList)
        fetchUserTicks();
        fetchPointMeta();
    },[isLoaded])


    const initialQuery = async () => {
        const ul = document.getElementById(`routeList_${type}`)
        const list = ul.getElementsByTagName("li");   


        for(const el of list ){
            
            if(el.id === type){
                el.style.display = "";
            } else {
                el.style.display = "none";
            }
            
        }
    }

    console.log(routeList)

    const searchRoutes = async () => {
        const input = document.getElementById(`input_${type}`);
        const filter = input.value.toUpperCase();
        const ul = document.getElementById(`routeList_${type}`);
        const li = ul.getElementsByTagName("li");
        console.log(li.length)
        for (var i = 0; i < li.length; i++) {
            const txtValue = li[i].textContent || li[i].innerText;
            if(li[i].id === type){
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            }
        }
    }



    const fetchRoutes = async () => {
    
        await fetch("https://elevationrockgym.com/api/fetch_all_routes.php",{
            method: 'POST'
        })
          .then(result => result.json())
          .then(
            (result) => {
              setRouteList(result);
              setIsLoaded(true)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true)
              setError(error)
            }

          )
              
      }

    ///// Fetch User Ticks ////

    const fetchUserTicks = async () => {
        const data = await fetch("https://elevationrockgym.com/api/fetch_user_upallnight_ticks.php",{
          method: 'POST',
          body: JSON.stringify({
            user: userID     
          })
        }).then(result => result.json())
          .then(
            (result) => {
              setUserTicks(result)
              setIsUserLoaded(true)
              console.log(result);
            }
        )
    }

    // Fetch up all night point meta //

    const fetchPointMeta = async () =>{
        const data = await fetch("https://elevationrockgym.com/api/upallnight_points.json",{
            method: 'POST'
        })
        .then(result => result.json())
        .then(
            (result) => {
                console.log(result)
                setPointMeta(result)
                
            }

        )
        console.log(pointMeta)
     }
    
        
        return(
            <div className="route_list">
                <div>
                    <input type="text" id={`input_${type}`} onKeyUp={searchRoutes} placeholder="Search for names, grades, colors, setters.." title="Type in search criteria"></input>
                </div>    
                <ul id={`routeList_${type}`}>
                    {routeList.map((item => (

                            
                            <li key={item.id} id={item.type}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h1>
                                                
                                                {item.name.split('_').join(' ')}
                                                </h1>
                                                <h2>
                                                   
                                                    {pointMeta[item.id]['points']} Points
                                                </h2>
                                                <h2>
                                                    {pointMeta[item.id]['feet']} Feet
                                                </h2>
                                            </td>
                                            <td>
                                                <Ticks route_info={pointMeta[item.id]} userID={userID} route={item} ticks={userTicks[item.id]}/>                                             
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                    )))}
                </ul>
            </div>
        )
    

}

export default Scorecard;