import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Image from 'react-bootstrap/Image'
import ListGroup from 'react-bootstrap/ListGroup';
import Spinner from 'react-bootstrap/Spinner';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import ViewTicks from './viewTicks'

const AddTick = props => {

    const route = props.route;
    const bcWeek = route.BC;
    const userID = props.userID;
    const routeID = route.id;
    const grade = props.grade;
    const type = route.type;
    const location = route.location;
    const [value, setValue] = useState('Hangdog');
    const [addTickLoading, setAddTickLoading] = useState(false);
    const [golden, setGolden] = useState('false');
    const [showGolden, setShowGolden] = useState(false);


    const [show, setShow] = useState(false);
    

    useEffect(()=>{
        if(golden === 'true'){
            setShowGolden(true);
        }

      },[golden])

    const handleClose = () => {
        setShow(false);
        setShowGolden(false);
    }
    const handleShow = () => setShow(true);



    
    const handleChange = async (event) => {
        setValue(event.target.value)
    }
    
    const handleSubmit = async (event) => {

        
        event.preventDefault();
        setAddTickLoading(true);

        const addNewtick = await fetch('https://elevationrockgym.com/api/add_tick2.php',{
            method: 'POST',
            body: JSON.stringify({
            user: userID,
            id: routeID,
            type: type, 
            style: value,
            grade: grade,
            bc: bcWeek,
            location: location
            })
        }).then(result=>result.text()).then((result)=>{
            setAddTickLoading(false);
            handleClose();
    
            setGolden(result);
        })

    }

    return(
        <div>
            <table>
                <tr>
                    <td>
                        <Button variant="warning" onClick={handleShow}>Add Tick</Button>
                    </td>
                    <td>
                        <ViewTicks userID={userID} routeID={routeID} />
                    </td>
                </tr>
            </table>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add Tick</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form> 
                <Form.Group >
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Tick</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleChange}>
                            <option value="Hangdog">Hangdog</option>
                            <option value="Redpoint">Redpoint</option>
                            <option value="Onsight">Onsight/Flash</option>
                        </Form.Control>
                    </InputGroup>
                    </Form.Group>
                    <Button variant="primary" disabled={addTickLoading} onClick={handleSubmit}>
                        Submit
                    </Button>
                    </Form>
                </Modal.Body>
            </Modal>
            <Modal show={showGolden} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>You found a golden ticket!</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                Show this to the Front Desk to recieve your prize!
                    <Image src="https://elevationrockgym.com/api/images/GoldenTicket.png" fluid/>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                </Modal.Footer>
            </Modal>
           
        </div>
    )
    
}

export default AddTick;