import React, {useState, useEffect} from "react";
import { Link } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import ListGroup from 'react-bootstrap/ListGroup';
import './routes.css';

const Routes = props => {

    const type = props.type;

    const [routeList,setRouteList] = useState([]);
    const [isLoaded,setIsLoaded] = useState(false);
    const [error,setError] = useState(null)

    useEffect(()=>{
        fetchRoutes();
    },[]);

    useEffect(()=>{
        initialQuery();
    },[isLoaded])


    const initialQuery = async () => {
        const ul = document.getElementById(`routeList_${type}`)
        const list = ul.getElementsByTagName("li");   


        for(const el of list ){
            
            if(el.id === type){
                el.style.display = "";
            } else {
                el.style.display = "none";
            }
            
        }
    }

    const searchRoutes = async () => {
        const input = document.getElementById(`input_${type}`);
        const filter = input.value.toUpperCase();
        const ul = document.getElementById(`routeList_${type}`);
        const li = ul.getElementsByTagName("li");
        for (var i = 0; i < li.length; i++) {
            const txtValue = li[i].textContent || li[i].innerText;
            if(li[i].id === type){
                if (txtValue.toUpperCase().indexOf(filter) > -1) {
                    li[i].style.display = "";
                } else {
                    li[i].style.display = "none";
                }
            }
        }
    }



    const fetchRoutes = async () => {
    
        await fetch("https://elevationrockgym.com/api/fetch_all_routes.php",{
            method: 'POST'
        })
          .then(result => result.json())
          .then(
            (result) => {
                console.log(result)
              setRouteList(result);
              setIsLoaded(true)
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
              setIsLoaded(true)
              setError(error)
            }
          )
              
      }

        return(
            <div className="route_list">
                <div>
                    <input type="text" id={`input_${type}`} onKeyUp={searchRoutes} placeholder="Search for names, grades, colors, setters.." title="Type in search criteria"></input>
                </div>    
                <ul id={`routeList_${type}`}>
                    {routeList.map((item => (
                        <Link key={item.id} className="route_list_item" to={{ pathname: `/qr/${item.qr}` }}>
                            <li key={item.id} id={item.type}>
                                <table>
                                    <tbody>
                                        <tr>
                                            <td>
                                                <h1>
                                                {item.name.split('_').join(' ')}
                                                </h1>
                                            </td>
                                            <td>
                                                <p>
                                                {item.cgrade}
                                                </p>                
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>

                                            </td>
                                            <td>
                                                <p>
                                                {item.setter.split('_').join(' ')}
                                                </p>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </li>
                        </Link>
                    )))}
                </ul>
            </div>
        )
    

}


export default Routes;