import React, {useState, useEffect} from "react";
import { Alert } from "react-bootstrap";
import { Button, ButtonToolbar, Input, ButtonGroup, Form, Spinner, Modal, InputGroup } from 'react-bootstrap';

import './ticks.css';
import AddTick from "../Forms/AddTick";

const Ticks = props => {
    const route = props.route;
    const qr = route.qr;
    const routeID = route.id;
    const type = route.type;
    const grade = route.cgrade;
    const bc = route.bc;
    const ticks = props.ticks;
    const userID = props.userID;
    const pointMeta = props.route_info.points;
    const feet = props.route_info.feet;

    

    const [tick,setTick] = useState(0);
    const pointTotal = Number(ticks)*Number(pointMeta)
    console.log(pointTotal)
    const [points,setPoints] = useState(Number(ticks)*Number(pointMeta));
    const [addTickLoading, setAddTickLoading] = useState(false);

    

    useEffect(()=>{
        if(ticks=='2'){
            setAddButton(true)
        }
        if(ticks==undefined){
            setPoints('0')
        } else {
            setTick(ticks)
            setPoints(pointTotal)
        }
    },[ticks,pointTotal])

    const pointValue = 400;

    const [value, setValue] = useState('Redpoint');
    const [addButton,setAddButton] = useState(false);


    const [show, setShow] = useState(false);


    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);



    
    const handleChange = async (event) => {
        setValue(event.target.value)
    }
    

    const addTick = async () => {
        setAddTickLoading(true);


        const addNewtick = await fetch('https://elevationrockgym.com/api/add_tick.php',{
            method: 'POST',
            body: JSON.stringify({
            user: userID,
            id: routeID,
            type: type, 
            style: value,
            grade: grade,
            bc: bc
            })
        })
        .then(result => result.text())
        .then((result)=>{
            console.log(result)
            setTick(result);
            setPoints(result*pointMeta)
            setShow(false);
            setAddTickLoading(false);
            if(result=='2'){
                setAddButton(true);
            }
        })
        
    }


    return(
        <div>
        <div className='ticks'>
            
                <ButtonToolbar className="mb-3" aria-label="Toolbar with Button groups">
                    <ButtonGroup className="me-2" aria-label="blagroup">
                        <Button disabled={addButton} onClick={handleShow} variant="primary">
                            +
                        </Button>
                        <Button disabled='true' variant="dark">
                            {tick} Tick(s)
                        </Button>
                        <Button disabled variant="danger">
                            {points} Points
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>
            
        </div>
        <Modal show={show} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Add Tick</Modal.Title>
            </Modal.Header>
            <Modal.Body>
            <Form> 
            <Form.Group >
                <InputGroup className="mb-2">
                    <InputGroup.Prepend>
                        <InputGroup.Text>Tick</InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control as="select" onChange={handleChange}>
                        <option value="Redpoint">Redpoint</option>
                        <option value="Onsight">Onsight</option>
                    </Form.Control>
                </InputGroup>
                </Form.Group>
                <Button variant="primary" disabled={addTickLoading} onClick={addTick}>
                    Submit
                </Button>
                </Form>
            </Modal.Body>
        </Modal>
        </div>
    )
}

export default Ticks;