import React, {useState, useEffect} from 'react';
import { Spinner, Table, Accordion, Card, Button, ListGroup, Modal } from 'react-bootstrap';
import './listEquipment.css';
import FormControl from 'react-bootstrap/Form';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';

const EditEquipment = props => {
    const userId = props.userId;
    const equipmentId = props.equipmentId;
    const equipmentDateP = props.equipmentDate;
    const equipmentIdentifierP = props.equipmentIdentifier;
    const equipmentDescriptionP = props.equipmentDescription;
    const equipmentTypeP = props.equipmentType;
    const [equipmentDate,setEquipmentDate] = useState(equipmentDateP);
    const [equipmentIdentifier,setEquipmentIdentifier] = useState(equipmentIdentifierP);
    const [equipmentDescription,setEquipmentDescription] = useState(equipmentDescriptionP);

    // Initial Modal
    const [showEditEquipment, setShowEditEquipment] = useState(false);
    const handleCloseEditEquipment = () => setShowEditEquipment(false);
    const handleShowEditEquipment = () => {
        setShowEditEquipment(true);

    }
    //Modal Result
    const [editEquipmentResult,setEditEquipmentResult] = useState("");
    const [showEditEquipmentResult, setShowEditEquipmentResult] = useState(false);
    const handleCloseEditEquipmentResult = () => setShowEditEquipmentResult(false);

    const handleEquipmentDateChange = event => {
        setEquipmentDate(event.target.value);
    }

    const handleEquipmentIdentifierChange = event => {
        setEquipmentIdentifier(event.target.value);
    }
    const handleEquipmentDescriptionChange = event => {
        setEquipmentDescription(event.target.value);
    }


    const editEquipment = async () => {
        const data = await fetch("https://elevationrockgym.com/api/edit_equipment.php",{
            method: 'POST',
            body: JSON.stringify({
                id: equipmentId,
                identifier: equipmentIdentifier,
                description: equipmentDescription,
                date: equipmentDate,
                user_id: userId,
                identifier_p: equipmentIdentifierP,
                date_p: equipmentDateP,
                description_p: equipmentDescriptionP   
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setEditEquipmentResult(result);
            setShowEditEquipment(false);
            setShowEditEquipmentResult(true);
            props.equipmentQuery();
        });
    }

    return(
        <div>
            <Button onClick={handleShowEditEquipment}>Edit {equipmentTypeP}</Button>
            <Modal show={showEditEquipment} onHide={handleCloseEditEquipment}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {equipmentTypeP}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Date</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" value={props.equipmentdate} onChange={handleEquipmentDateChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Identifier</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="number"  min="1" max="40" placeholder={props.equipmentIdentifier} onChange={handleEquipmentIdentifierChange} />
                    </InputGroup>
                    <InputGroup>
                        <InputGroup.Prepend>
                            <InputGroup.Text>Description</InputGroup.Text>
                        </InputGroup.Prepend>
                        <FormControl onChange={handleEquipmentDescriptionChange} placeholder={props.equipmentDescription} as="textarea" aria-label="Description" />
                    </InputGroup>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={editEquipment}>Edit</Button>
                    <Button variant="secondary" onClick={handleCloseEditEquipment}>Close</Button>
                </Modal.Footer>
            </Modal>
            <Modal show={showEditEquipmentResult} onHide={handleCloseEditEquipmentResult}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit {equipmentTypeP} Result</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {editEquipmentResult}
                </Modal.Body>
                <Modal.Footer>
                    
                    <Button variant="secondary" onClick={handleCloseEditEquipmentResult}>Close</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}

export default EditEquipment;