import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import './routeMap.css';

class RouteMap extends Component {
    constructor(props){
        super(props);
        this.state={searchOptions: this.props.searchOptions, type: this.props.type, isLoaded: false, error: null, routeQuery: [], redirect: []}
        this.fetchAreaMap = this.fetchAreaMap.bind(this)
        this.handleMouseDown = this.handleMouseDown.bind(this)
    }

    componentDidUpdate(){
        this.fetchAreaMap();
    }

    componentDidMount() {
        this.fetchAreaMap();
    }
    
    handleMouseDown(e) {
        e.preventDefault();
        const ctx = this.refs.canvas.getContext("2d")
        const mouseX = e.offsetX * (e.target.width/e.target.offsetWidth);
        const mouseY = e.offsetY * (e.target.height/e.target.offsetHeight);
        this.state.routeQuery.forEach(item => {
            const circle = new Path2D();
            circle.arc(item.coords[0],item.coords[1],10,0,2 * Math.PI)
            ctx.fillStyle = item.fillColor
            ctx.fill();  
            if(ctx.isPointInPath(circle,mouseX,mouseY)){
                this.props.history.push(`/qr/${item.qr}`)


            } 
        })
    }
    
    async fetchAreaMap(){
    

        const highlightedRoutes = this.props.searchOptions;
        const searchArray = {cgrade: highlightedRoutes['cgrade'],id: highlightedRoutes['id'],type: this.props.type}
        await fetch("https://elevationrockgym.com/api/display_map.php",{
            method: 'POST',
            body: JSON.stringify(searchArray)
        })
        .then(result => result.json())
        .then(
            (result) => {
            if(JSON.stringify(result) !== JSON.stringify(this.state.routeQuery)){
                this.setState({'routeQuery': result});
                this.setState({'isLoaded': true});
            }
            },
            // Note: it's important to handle errors here
            // instead of a catch() block so that we don't swallow
            // exceptions from actual bugs in components.
            (error) => {
            if(JSON.stringify(this.state.error) !== JSON.stringify(error)){    
                this.setState({'isLoaded': true})
                this.setState({'error': error})
            }
            }
        )
        const routes = this.state.routeQuery;
        console.log(routes)
        const canvas = this.refs.canvas


        if(canvas){
            canvas.addEventListener('click', this.handleMouseDown)

            const ctx = canvas.getContext("2d")
            const img = new Image();
            const typeString = this.state.type;
            if(typeString === 'B'){
            
                img.src = "https://www.elevationrockgym.com/app/images/upstairs_bouldering_map.svg";
            } else if(typeString === 'DB') {
                img.src = "https://www.elevationrockgym.com/app/images/cave.svg";
            } else if(typeString === 'R') {
                img.src = "https://www.elevationrockgym.com/app/images/ropes.svg";  
            }
            img.onload = () => {
                ctx.drawImage(img, 0, 0,1134,766);
                routes.forEach(item=> {
                    ctx.beginPath();
                    ctx.fillStyle = item.fillColor
                    ctx.arc(item.coords[0],item.coords[1],10,0,2 * Math.PI)
                    ctx.fill(); 
                })
            } 
        }

    }


    render(){

        return (
            <>
                <div className="routeMap">
                    <canvas ref="canvas" width="1134" height="766"  />
                </div>
            </>
        )
    }
}

export default withRouter(RouteMap);