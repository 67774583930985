import React, { useState, useEffect, Component} from 'react';
import { useAuth0 } from "@auth0/auth0-react";
import {Spinner, Tabs, Tab, Container, Row, Col, Image} from 'react-bootstrap';
import LoginButton from '../Login/loginButton';
import MyStats from '../Everest/myStats';
import EverestLeaderboard from '../Everest/leaderboard';

const Everest = props =>{
 
    useEffect(() => {
        window.scrollTo(0, 0);
      });
     

    const [isChecked, setIsChecked] = useState(false);
    const [userProfile,setUserProfile] = useState([]);
    const [statusComplete,setStatusComplete] = useState(false);


    const { user, isAuthenticated } = useAuth0();



    if(isAuthenticated ){
        return(
            <div>
                <Container>
                    <Row>
                        <Col>
                            <Image src="https://www.elevationrockgym.com/app/images/alslogo.jpeg" fluid="true" />
                        </Col>
                        <Col>
                            
                            <Row>
                                <Image src="https://www.elevationrockgym.com/app/images/blackstone.png" fluid="true" />
                            </Row>
                            <Row>
                                <Image src="https://www.elevationrockgym.com/app/images/edelrid.png" fluid="true" />
                            </Row>
                            <Row>
                                <Image src="https://www.elevationrockgym.com/app/images/trango.jpeg" fluid="true" />
                            </Row>
                            
                        </Col>
                    </Row>
                </Container>
                    
                <Tabs defaultActiveKey="info" id="everest" >
                    <Tab eventKey="info" title="Info">
                        <div>
                            <div>
                            Join us for the first edition of Climb Everest. Compete against others or yourself, and see how far you can climb over the course of 60 days.
                            </div>
                            <div></div>
                            <div>
                                <strong>Rules and Prizes</strong>
                                <ul>
                                    <li>The route must be completed in order to tick the route. Completion of the route may be done with falls or 'taking'.</li>
                                    <li>Each climb ticked will count towards your total vertical feet climbed.</li>
                                    <li>Ticks must be recorded using our Elevation Rock Gym web app between Feb. 1st and April 1st</li>
                                    <li>Prizes awarded for each milesone acheived, mini-contests and most vertical feet climbed!</li>
                                </ul>
                            </div>
                            <div>
                                <strong>Registration</strong>
                                <ul>
                                    <li>You must signup for an account on our web application by clicking Log-in.</li>
                                    <li>Please report all application issues to brian@elevationrockgym.com</li>
                                </ul>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="mystats" title="My Stats">
                        <MyStats userID={user.sub} />
                    </Tab>
                    <Tab eventKey="leaderboard" title="Leaderboard">
                        <EverestLeaderboard />
                    </Tab>
                </Tabs>
            </div>
            
        )   
    } else {
        return(
            <div>
            <div>
                    You must login to view this page. Click below to log in or register an account.
                </div>
                <LoginButton />
            </div>
        )
    }
   
}

export default Everest;
