import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup'
import Toast from 'react-bootstrap/Toast'

const EditRoute = props => {

    const route = props.route;
    const userID = props.user.sub;
    const routeID = props.route.id;

    const [show, setShow] = useState(false);
    const [show2, setShow2] = useState(false);
    const [routeName,setRouteName] = useState(route.name);
    const [setter,setSetter] = useState(route.setter);
    const [color,setColor] = useState(route.color);
    const [date,setDate] = useState(route.date);
    const [type,setType] = useState(route.type);
    const [location,setLocation] = useState(route.location);
    const [week,setWeek] = useState(route.BC);
    const [points,setPoints] = useState("0");
    const [showA, setShowA] = useState(false);
    const [prevWeek, setPrevWeek] = useState(route.BC)
  
    const toggleShowA = () => setShowA(!showA);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleShow2 = () => setShow2(true);
    console.log(prevWeek)

    
    const edit = async (userID) => {
        console.log(routeName)
        const data = await fetch("https://elevationrockgym.com/api/edit_route.php",{
            method: 'POST',
            body: JSON.stringify({
                route_id: routeID,
                user_id: userID,
                route_name: routeName,
                color: color,
                date: date,
                type: type,
                location: location,
                setter: setter,
                week: week,
                points: points,
                prev_week: prevWeek
            })
        })
        .then(result => result.text())
        .then((result)=>{
            setShow(false);
            console.log(result)
        });
       
    }

    const handleRouteNameChange = event => {
        setRouteName(event.target.value)
        console.log(routeName)
    }

    const handleColorChange = event => {
        setColor(event.target.value);
    }

    const handleSetterChange = event => {
        setSetter(event.target.value);
    }

    const handleDateChange = event => {
        setDate(event.target.value);
    }

    const handleLocationChange = event => {
        setLocation(event.target.value);
    }

    const handleTypeChange = event => {
        setType(event.target.value);
    }

    const handlePointsChange = event => {
        setPoints(event.target.value)
    }

    const handleWeekChange = event => {
        setWeek(event.target.value)
    }

    useEffect(()=>{
        if((type === "B") || (type === "DB")){
            setShowA(true);
        } else {
            setWeek("0");
            setShowA(false);
        }
    },[type])


    return(
        <div>
            
            <Button onClick={handleShow} >Edit Route </Button>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit Route</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                <Form.Group >
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Route Name</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="text" onChange={handleRouteNameChange} value={routeName} placeholder={route.name.split('_').join(' ')}/>
                    </InputGroup>  
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Color</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleColorChange}>
                            <option>{route.color}</option>
                            <option>Pink</option>
                            <option>Red</option>
                            <option>Orange</option>
                            <option>Blue</option>
                            <option>Green</option>
                            <option>Black</option>
                            <option>White</option>
                            <option>Tan</option>
                            <option>Yellow</option>
                            <option>Purple</option>
                            <option>Seafoam</option>
                            <option>Grey</option>
                            <option>Neon Green</option>
                            <option>Other</option>

                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Setter</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleSetterChange}>
                            <option>{route.setter.split('_').join(' ')}</option>
                            <option>Brian</option>
                            <option>Andy</option>
                            <option>Monica</option>
                            <option>Denzil</option>
                            <option>Cody</option>
                            <option>Stef</option>
                            <option>Seersha</option>
                        </Form.Control>
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Date</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="date" value={route.date} onChange={handleDateChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Location</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control type="number" placeholder={route.location} min="1" max="40" onChange={handleLocationChange} />
                    </InputGroup>
                    <InputGroup className="mb-2">
                        <InputGroup.Prepend>
                            <InputGroup.Text>Type</InputGroup.Text>
                        </InputGroup.Prepend>
                        <Form.Control as="select" onChange={handleTypeChange}>
                            <option>{route.type}</option>
                            <option value="R">Ropes</option>
                            <option value="B">Upstairs Bouldering</option>
                            <option value="DB">Downstairs Bouldering</option>
                        </Form.Control>
                    </InputGroup>                       
                </Form.Group>
                <Toast show={showA} onClose={toggleShowA}>
                        <Toast.Header>
                            <strong className="mr-auto">Boulder Club Information</strong>
                        </Toast.Header>
                        <Toast.Body>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Boulder Club Week</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control as="select" onChange={handleWeekChange}>
                                <option value={week}>{week}</option>
                                <option value="0">Not in Boulder Club</option>
                                <option value="1">1</option>
                                <option value="2">2</option>
                                <option value="3">3</option>
                                <option value="4">4</option>
                                <option value="5">5</option>
                                <option value="6">6</option>
                            </Form.Control>
                            </InputGroup>
                            <InputGroup className="mb-2">
                            <InputGroup.Prepend>
                                <InputGroup.Text>Points</InputGroup.Text>
                            </InputGroup.Prepend>
                            <Form.Control type="text" onChange={handlePointsChange} value={points} />
                            </InputGroup>
                        </Toast.Body>
                    </Toast>   

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={handleClose}>Close</Button>
                    <Button variant="primary" onClick={()=>edit(userID)}>Save changes</Button>
                </Modal.Footer>
            </Modal>  
        </div>
    )
}


export default EditRoute;