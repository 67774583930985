import React, {useState, useEffect} from 'react';
import Button from 'react-bootstrap/Button'
import Toast from 'react-bootstrap/Toast';
import Spinner from 'react-bootstrap/Spinner';
import Image from 'react-bootstrap/Image'



const GoldenTicket = props => {
    const userID = props.user;

    const [show, setShow] = useState(true);
    const [golden,setGolden] = useState(false);
    const [showGolden,setShowGolden] = useState(false);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error,setError] = useState(null);
    const toggleShow = () => setShow(!show);
 
  
    const fetchGolden = async () => {
      
      await fetch("https://elevationrockgym.com/api/golden_ticket.php",{
        method: 'POST',
        body: JSON.stringify({
        user: userID
        })
        }).then(result => result.text())
        .then(
          (result) => {
              console.log(result)
            setIsLoaded(true)
            setGolden(result)
          },
          // Note: it's important to handle errors here
          // instead of a catch() block so that we don't swallow
          // exceptions from actual bugs in components.
          (error) => {
            setIsLoaded(true)
            setError(error)
          }
        )
  
            
    }

    useEffect(()=>{
        if(golden === 'true'){
            setShowGolden(true);
        }

      },[golden])

    useEffect(()=>{
      fetchGolden();
    },[])
    if(isLoaded && showGolden == true){
      return(
        <div>
          <Toast show={show} onClose={toggleShow}>
            <Toast.Header>
              <strong className="mr-auto">You found a golden ticket!</strong>
            </Toast.Header>
            <Toast.Body>
                Show this to the Front Desk to recieve your prize!
                <Image src="https://elevationrockgym.com/api/images/GoldenTicket.png" fluid/>
            </Toast.Body>
          </Toast>
        </div>
      )
  } else if(isLoaded && showGolden == false){
    return(
        <div>

        </div>
    )
  } else {
    return(
      <div>
            <div>
                <Spinner animation="border" role="status" />
            </div>
      </div>
    )
  }
  }
  
  
  
  export default GoldenTicket;